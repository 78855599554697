$primary: #626262;
$secondary: #d0d0d0;
$white: #fff;

.menu {
  overflow: visible;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    right: 6px;
    border: solid $white 3px;
    background: $white;
    padding: 6px;
    transform: rotate(-135deg);
  }
}

.menuButton {
  display: block;
  height: 30px;
  width: 30px;
  margin: 0 0 0 18px;
  cursor: pointer;
}

.menuItems {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 10px;
  padding: 0 22px 10px;

  .menuLink {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    transition: transform 0.3s;

    &:not(:disabled):hover {
      transform: scale(1.05);
    }
  }
}

.menuHeader {
  display: block;
  width: 100%;
  margin: 6px 0 22px;
  padding: 20px 0 0 0;
  outline: none;
  border-bottom: solid 1px $secondary;

  span {
    display: block;
    padding: 0 0 4px 22px;
    font-size: 0.875rem;
    font-weight: 700;
    font-family: 'Open Sans';
    color: $primary;
  }
}
