.marketplace {
  min-width: 440px;
  width: 76.5625%;
  margin: 30px auto 0;
}

.card {
  display: flex;
  flex-direction: row;
}

.menu {
  width: 200px;
  margin: 0 0 0 -1.625em;

  ul {
    display: flex;
    flex-direction: column;
    margin: -.25em 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: .25em 0;
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin: 0;
    padding: 0 2em;
    border: 0;
    background: none;
    font-size: .875em;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    color: #393939;

    img {
      width: 24px;
      margin-right: .5em;
    }

    &.isActive {
      background-color: #f6f6f6;
    }

    &:hover {
      background-color: #f6f6f6;
    }
  }
}

.content {
  margin-left: 2em;
  flex: 1;
}
