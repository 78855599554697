.clientDetails {
  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  display: flex;
  flex-direction: column;
  padding: 1.5em 1em 0;
  margin-bottom: -12px;

  .cardHead {
    margin-bottom: 12px;
  }

  > div {
    margin: 0 0 0.3em;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
