.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em 0;
}

.cardHead {
  display: flex;
  align-items: center;
  margin-bottom: 1.4em;
  padding-bottom: 1.4em;
  border-bottom: 1px solid #eee;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.3;
  color: #393939;

  .item {
    display: flex;
    align-items: center;
  }

  .headActions {
    position: absolute;
    right: 1.3em;

    a,
    button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 32px;
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      border-radius: 3px;
      font-weight: 700;
      line-height: 1.4;
      color: #393939;
      transition: all 0.3s;

      &:hover {
        opacity: 0.75;
      }

      &:active,
      &:focus {
        outline: 0;
      }

      img {
        height: 22px;
      }
    }
  }

  .back {
    margin-right: 0.5em;

    a,
    button {
      position: relative;
      top: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 0;
      padding: 0;
      background: none;

      &:active,
      &:focus {
        outline: 0;
      }
    }

    button {
      width: 16px;
      height: 16px;
    }

    .arrowLeft {
      position: relative;
      left: 4px;
      width: 14px;
      height: 14px;
      border: 2px solid #393939;
      border-top: 0;
      border-right: 0;
      transform: rotate(45deg);
    }
  }

  .icon {
    margin-right: 0.5em;

    img {
      width: 100%;
      height: 100%;
      max-width: 28px;
    }

    &:not(:first-child) {
      margin-left: 32px;
    }
  }
}

.toolbar {
  height: 32px;
}

.actions {
  display: flex;
  justify-content: center;
  margin: 0 -5px;

  a,
  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 32px;
    margin: 0 5px;
    padding: 0;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 0.9375em;
    font-weight: 700;
    line-height: 1.4;
    text-decoration: none;
    color: #393939;
    transition: all 0.3s;

    &[disabled] {
      border-color: lighten(#e3e3e3, 5%);
      cursor: not-allowed;
    }

    &:hover:not([disabled]) {
      background-color: darken(#ffffff, 2%);
    }

    &:active,
    &:focus {
      outline: 0;
    }

    img {
      height: 18px;
    }
  }
}

.confirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875em;
  font-weight: 700;

  .text {
    line-height: 1;
  }

  .actions {
    display: flex;
    margin: 0 -5px;

    button {
      margin: 0 5px;
    }
  }
}

.back {
  position: absolute;
  left: 1.625em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20px;
  height: 32px;
  padding: 0;
  border: 0;
  background: #ffffff;

  &:hover {
    opacity: 0.75;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  .arrowLeft {
    transform: rotate(45deg);
    position: relative;
    left: 6px;
    width: 12px;
    height: 12px;
    border: 2px solid #393939;
    border-top-width: 0;
    border-right-width: 0;
  }
}

.cardFooter {
  display: flex;
  justify-content: center;
  margin-top: 1.75em;
  padding-top: 1.75em;
  border-top: 1px solid #eee;

  .outline {
    display: inline-flex;
    align-items: center;
    height: 32px;
    margin: 0 0.5em;
    padding: 0 1.2em;
    border: 1px solid #4c8fc9;
    border-radius: 50px;
    background: none;
    font-size: 0.9375em;
    font-weight: 700;
    line-height: 1.4;
    text-decoration: none;
    color: #4c8fc9;
    transition: all 0.3s;

    &:hover {
      opacity: 0.75;
    }

    &:active,
    &:focus {
      outline: 0;
    }
  }

  .button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    height: 36px;
    margin: 0 0.5rem;
    padding: 0 1.2rem;
    border: 0;
    border-radius: 3px;
    background: #c1c1c1;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.4;
    text-decoration: none;
    color: #ffffff;
    transition: opacity 0.3s;

    &.isGreen {
      background: #97a500;
    }

    &[disabled] {
      cursor: not-allowed;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -9px;
        width: 18px;
        height: 18px;
        border: 2px solid #cccccc;
        border-right-color: #333333;
        border-radius: 50%;
        z-index: 2;
        animation: rotate 0.6s infinite linear;
      }
    }

    &:hover {
      opacity: 0.75;
    }

    &:active,
    &:focus {
      outline: 0;
    }
  }
}

.subtitle {
  display: flex;
  align-items: center;
  margin: 1.5em 0 0;
  font-size: 0.93875em;
  font-weight: 700;
  line-height: 1.1;
  color: #393939;

  &:first-of-type {
    margin-top: 0;
  }

  .icon {
    width: 3.46em;

    img {
      height: 24px;
    }
  }
}

.spinner {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.fadeContent {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: -1.75em 0;

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin: 0;
    font-size: 0.875em;
    line-height: 1;
    color: #919191;
  }

  .item {
    display: flex;
    align-items: center;
    height: 64px;
    border-bottom: 1px solid #eeeeee;
    line-height: 1;

    &:last-child {
      border-bottom: 0;
    }

    .icon {
      flex: 0 0 24px;
      margin-right: 0.6em;
    }

    .text {
      display: flex;
      align-items: center;
      flex: 1;
      height: 24px;
      overflow: hidden;

      .highlight {
        font-size: 1em;
        font-weight: 700;
      }

      .separator {
        margin: 0 0.5em;
      }

      .normal {
        position: relative;
        top: 1px;
        height: 24px;
        font-size: 0.875em;
        font-weight: 600;
        line-height: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .date {
      position: relative;
      top: 1px;
      flex: 0 0 120px;
      font-size: 0.875em;
      text-align: right;
      color: #919191;
    }
  }
}

.dropdownTrigger {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  font-weight: 700;
  color: #393939;
  transition: all 0.3s;

  &:after {
    content: '';
    position: relative;
    top: 4px;
    margin-left: 0.3em;
    border: 4px solid #393939;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active,
  &:focus {
    outline: 0;
  }
}

.dropdown {
  .menu {
    position: absolute;
    transform: translateY(1em);
    width: 235px;
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      top: -5px;
      left: 10px;
      transform: rotate(45deg);
      width: 0;
      height: 0;
      border: solid 0.5625em transparent;
      border-color: #ffffff transparent transparent #ffffff;
      border-width: 6px;
      box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.08);
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      border-radius: 3px;
      background-color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 1em;
      width: 100%;
      height: 32px;
      border: 0;
      background: none;
      font-size: 0.8em;

      &:hover {
        background: darken(#ffffff, 2%);
      }

      &:active,
      &:focus {
        outline: 0;
        background: darken(#ffffff, 5%);
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
