.grayButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 36px;
  margin: 0;
  padding: 0 1em;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #f0f0f0;
  line-height: 1.2;
  color: #3c3c3c;
  transition: all 0.3s;

  &:hover:not([disabled]) {
    background-color: darken(#ffffff, 2%);
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &[disabled] {
    border-color: lighten(#e3e3e3, 5%);
    cursor: not-allowed;
  }

  img,
  svg {
    height: 16px;
    margin-right: 0.5em;
  }
}

.spinnerWrapper {
  width: 36px;
  height: 36px;

  svg {
    margin: 0;
  }
}
