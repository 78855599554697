.colorButton {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  height: 36px;
  margin: 0 0.5em;
  padding: 0 1.2em;
  border: 0;
  border-radius: 3px;
  background: #c1c1c1;
  font-size: 0.9375em;
  font-weight: 700;
  line-height: 1.4;
  color: #ffffff;
  transition: opacity 0.3s;

  &:hover:not([disabled]) {
    opacity: 0.85;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.is-blue {
    background: rgb(88, 130, 193);
    color: #ffffff;

    &[disabled] {
      background-color: rgba(88, 130, 193, .75);
    }
  }

  &.is-green {
    background: rgb(151, 164, 31);
    color: #ffffff;

    &[disabled] {
      background-color: rgba(151, 164, 31, .75);
    }
  }

  &.is-red {
    background: rgb(201, 11, 11);
    color: #ffffff;

    &[disabled] {
      background-color: rgba(201, 11, 11, .75);
    }
  }

  img,
  svg {
    height: 16px;
    margin-right: 0.5em;
  }
}

.spinnerWrapper {
  width: auto;
  height: 66%;

  svg {
    margin: 0;
    color: rgba(255, 255, 255, .8);
  }
}
