.bootstrapInput,
.bootstrapInputError,
.label,
.title {
  font-family: 'Open Sans';
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
  font-weight: bold;
  font-size: calc(10px + 0.4rem);
  font-family: 'Open Sans';

  svg {
    margin-right: 6px;
  }
}

.bootstrapInput,
.bootstrapInputError {
  border-radius: 4px;
  padding: 8px 12px;
  font-size: calc(6px + 0.6rem);
  width: calc(100% - 24px);

  &:focus {
    border-color: #80bdff;
  }
}

.bootstrapInput {
  font-weight: normal;
  border: 1px solid #ced4da;
}

.bootstrapInputError {
  border: 1px solid red;
}

.error {
  font-family: 'Open Sans';
  font-weight: normal;
  color: #dc3545 !important;
  font-size: calc(6px + 0.3vw) !important;
  list-style-type: disc;
}

.plusButton {
  position: relative;
  background-color: #5882c1;
  box-shadow: none;
  color: #ffffff;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  outline: solid 15px #ffffff;
  z-index: 3;

  &:focus,
  &:hover {
    background-color: lighten(#5882c1, 10%);
  }
}

.grayLines {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  padding: 0;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #e9e9e9;
    z-index: 1;
  }
}

.service {
  margin-top: 25px;
}

.field {
  margin-top: 5px;
}

.removeButton {
  background-color: #ffffff;
  box-shadow: none;
  color: #5882c1;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  outline: none !important;
  transition: all 0.5s;

  &:focus,
  &:hover {
    background-color: #ffffff !important;
    filter: brightness(1.25);
  }
}

.removeButtonDisabled {
  background-color: #ffffff !important;
  color: #cce2f5 !important;
  outline: none !important;
}

.flexHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
