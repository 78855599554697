.deleteAttachmentModal {
  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 18px;

    p {
      font-weight: bold;
      margin: 0;
    }
  }

  hr {
    margin-bottom: 22px;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    .button {
      color: #ffffff;
      width: 132px;
      text-transform: none;
      border: none;
      padding: 8px;
      border-radius: 2px;

      &.confirm {
        background-color: #859905;
      }

      &.close {
        background-color: #be1104;
      }
    }
  }
}

.attachmentFiles {
  display: flex;
  flex-direction: row;
  padding-right: 0.5rem;

  input {
    display: none !important;
  }

  .documentsSpacing {
    padding-left: 0.5rem;
  }

  .title {
    margin-bottom: 0.6rem;
    font-weight: bold;
    font-size: calc(10px + 0.4vw);
  }

  .fileList {
    list-style: none;
    padding-left: 20px;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #007bff;
      font-weight: normal;
      font-size: 0.625rem;

      &:not(:last-of-type) {
        margin-bottom: 4px;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .deleteButton {
        height: 18px;
        color: #111827;
        cursor: pointer;
      }
    }
  }

  .isUploadingFile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    padding-left: 20px;
    width: 100%;

    > div {
      width: 28px;
    }
  }

  .uploadButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    margin-left: -4px;
    cursor: pointer;
    color: #007bff;
    font-weight: normal;
    font-size: 0.625rem;

    .addCircle {
      height: 20px;
    }
  }
}
