.emptyView {
  @import 'src/components/common/Card/shared/style';

  padding: 1.5em 1em 0;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  p {
    margin: 2em;
    text-align: center;
  }
}