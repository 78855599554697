.addCroqui {
  @import 'src/components/common/Form/shared/style';

  .field {
    padding: 0;

    &.actions {
      margin-top: 1.5em;
    }
  }
}
