.inspector {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .name {
    font-weight: 700;
  }

  .image {
    width: 24px;
    height: 24px;
    margin: 0 .5em 0 0;
    border-radius: 50%;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.neighborhood {
  flex: 0 0 100%;
  width: 100%;
  margin-top: .75em;
  font-size: 0.8125em;
  font-weight: 700;
  color: #919191;
}

.phone {
  flex: 0 0 100%;
  width: 100%;
  margin-top: .5em;
  font-size: 0.8125em;
  font-weight: 500;
  color: #919191;
}

