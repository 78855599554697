.Paper {
  margin-left: 1vw;
  margin-right: 1vw;
  background-color: #EAEAEA;
  border: #DEDEDE;
  border-style: solid;
  border-width: 2px;
  min-height: calc(100% - 4px);
}

.center {
  text-align: center;
}

.centerHeight {
  text-align: center;
  height: 100%;
}

.Header {
  font-size: calc(16px + 0.4vw);
  margin-top: 6vh;
}

.SubHeader {
  font-size: calc(14px + 0.4vw);
}

.Body {
  margin-top: 40px;
  margin-bottom: 5vh;
}

.Search {
  margin-left: 100px;
}
.textSm{
  font-size: 10px;
}
