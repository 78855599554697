.scheduleHeader {
  pointer-events: none;
  margin: 24px auto;
  font-size: 0.9rem;
  color: #1E1F22;
}

.scheduleNode div {
  background: #128FCF !important;
  border: none !important;
  border-radius: 0;
  color: transparent;
}
