.unproductiveWarning {
  padding-bottom: 1rem;

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: calc(10px + 0.4vw);
    font-weight: bold;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 1.25rem 0;
    padding: 0 40px;
  }

  li {
    display: flex;
    align-items: center;
    margin-top: .75rem;

    &:first-child {
      margin: 0;
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-right: .66rem;
    }

    .text {
      font-weight: 600;
      color: #212529;
    }
  }
}

.field {
  margin-top: 1rem;
  padding: 0 32px;

  label {
    display: flex;
    align-items: center;
    font-weight: 700;
    line-height: 1;
    color: #212529;
  }

  input[type="checkbox"] {
    margin-right: .5rem;
  }
}