.orderCancelPage {
  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  padding: 0 1em 0 0;
  font-size: 14px;

  .inspectorObservations {
    margin-bottom: 2em;
  }

  .image {
    max-width: 100%;
    max-height: 100%;
  }

  .field {
    &.isFlex {
      align-items: center;
      justify-content: flex-start;

      label {
        margin: 0;
      }

      .marginLeft {
        margin-left: .625em;
      }
    }

    :global .select {
      &__control {
        min-height: 0;
        height: 3em;
        font-size: 0.875em;
        border-color: #e3e3e3;
      }

      &__value-container {
        top: -2px;
      }

      &__single-value {
        line-height: 1.5;
      }
    }

    &.buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-top: 2em;

      .button {
        margin: 0;

        &:first-child {
          margin-right: 1em;
        }
      }
    }
  }

  .cardHead {
    .icon {
      margin-right: 0;
      line-height: 0;
    }
  }

  .subtitle {
    .icon {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
