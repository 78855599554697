.header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.information {
  color: #858585;
  font-size: calc(6px + 0.4vw);
  margin-top: 0;
  margin-bottom: 1vw;
  padding-left: calc(14px + 1.8vw);

  p {
    margin: 0;
  }

  ol {
    padding-left: 20px;

    li {
      display: flex;
    }
  }
}

.icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}

.link {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  text-align: left;
  color: #007bff;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

p .link {
  margin: 10px 0px;
}
