.searchBoxWrapper {
  position: relative;
  flex-grow: 2;
  margin-right: 4px;
  background: none;
}

.activeFilters {
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 9px 40px 10px 16px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background: #ffffff;
  transition: border-color 0.2s;

  &:focus-within {
    border-color: #498ECC;
    outline: 0;
  }

  .freeSearchTermInput {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    z-index: 10;
  }

  .filter {
    display: flex;
    align-items: center;
    height: 20px;
    margin: 0 2px;
    padding: 0 10px;
    border-radius: 50px;
    background-color: #658ec6;
    font-size: 12px;
    color: #ffffff;

    & + input {
      margin-left: 6px;
    }

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      max-width: 126px;

      @media screen and (max-width: 1919px) {
        max-width: 50px;
      }

      @media screen and (max-width: 1365px) {
        max-width: 48px;
      }
    }
  }

  .dateIntervalFilter {
    @extend .filter;

    > span {
      max-width: 300px !important;
    }
  }

  .startDateFilter {
    @extend .filter;

    > span {
      max-width: 300px !important;
    }
  }


  .endDateFilter {
    @extend .filter;

    > span {
      max-width: 300px !important;
    }
  }

  .closableFilter {
    @extend .filter;

    padding: 0 0 0 10px;

    > button {
      border: none;
      color: #ffffff;
      text-align: right;
      padding-right: 10px;
      margin-left: 2px;
      outline: none;
      transition: all 0.3s;
      cursor: pointer;
      border-radius: 0 15px 15px 0;

      &.addresses {
        background-color: #1A3A5F;
        &:hover {
          background-color: darken(#1A3A5F, 10%);
        }
      }

      &.order-types {
        background-color: #498ECC;
        &:hover {
          background-color: darken(#498ECC, 10%);
        }
      }
    }
  }

  #addresses {
    background-color: #1A3A5F;
  }

  #order-types {
    background-color: #498ECC;
  }
}

.searchBox {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  width: 100%;
  height: 42px;
  padding: 9px 39px 10px 16px;

  &:active,
  &:focus {
    outline: 0;
    border-color: #2684ff;
  }

  &.isSmall {
    font-size: 12px;
    padding: 8px 10px;
    height: 38px;
  }
}

.select {
  :global .select {
    &__control {
      &--is-focused {
        box-shadow: none;
      }
    }

    &__placeholder {
      font-size: 12px;
    }

    &__input {
      font-size: 12px;
    }

    &__single-value {
      font-size: 12px;
    }

    &__multi-value {
      border-radius: 15px;
      background-color: #658ec6;
      color: #ffffff;

      &__label {
        padding: 2px 2px 2px 8px;
        font-size: 12px;
        color: #ffffff;
      }

      &__remove {
        border-radius: 0 13px 13px 0;
        padding: 2px 5px 2px 2px;
        transition: background-color 0.3s;
        background-color: #658ec6;
        cursor: pointer;

        &:hover {
          color: #ffffff;
          background-color: darken(#658ec6, 10%);
        }
      }
    }

    &__value-container {
      max-width: calc(100% - 73px);

      [class$='Input'] {
        padding: 0;
        line-height: 0;
      }

      .select__input {
        padding: 4px 0;
        height: 24px;

        input {
          height: 100%;
        }
      }
    }

    &__value-container--is-multi {
      flex-wrap: wrap;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.advancedSearchButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 21;

  button {
    height: 42px;
    padding: 0 8px 1px;
    border: 0;
    background: none;
    color: #3c3c3c;

    &.isOpen {
      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      opacity: 0.75;
    }

    &:active,
    &:focus {
      outline: 0;
    }

    svg {
      transition: transform 0.3s ease;
    }
  }
}

.advancedSearchWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 99;

  .head {
    display: flex;
    align-items: center;
    height: 42px;
    padding: 9px 17px;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 600;
    color: #3c3c3c;
  }

  .body {
    padding: 9px 17px 17px;

    .dates {
      display: flex;
      justify-content: space-between;

      .field {
        flex: 0 0 calc(50% - 10px);
        width: calc(50% - 10px);

        &:first-child {
          margin-top: 10px;
        }

        > div {
          width: 100%;
        }

        label {
          display: block;
        }

        input {
          width: 100%;
          height: 38px;
          padding: 1px 8px 0;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 0.875em;

          &:focus {
            border-color: #2684ff;
            outline: 0;
          }
        }
      }
    }

    .field {
      margin: 10px 0 0;

      &:first-child {
        margin: 0;
      }
    }

    label {
      margin: 0 0 5px 0;
      font-size: 0.75em;
      font-weight: 600;
      text-transform: uppercase;
      color: #3c3c3c;
    }

    button[type='submit'] {
      display: block;
      height: 38px;
      margin: 10px 0 0 auto;
      padding: 0 20px 2px;
      border: 0;
      border-radius: 3px;
      background-color: #658ec6;
      font-size: 14px;
      color: #ffffff;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken(#658ec6, 6%);
      }

      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
}

:global {
  .slide-fade-enter {
    opacity: 0;
    max-height: 42px;
  }

  .slide-fade-enter-active {
    opacity: 1;
    max-height: 248px;
    transition: opacity 0.3s, max-height 0.3s ease;
    overflow: hidden;
  }

  .slide-fade-exit {
    opacity: 1;
    max-height: 248px;
  }

  .slide-fade-exit-active {
    opacity: 0;
    max-height: 42px;
    transition: opacity 0.3s, max-height 0.3s ease;
    overflow: hidden;
  }
}

.scheduleFilterButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 0 0;

  > button {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 20px;
    }

    &[type='submit'] {
      display: inline-block !important;
      margin: 0 !important;
    }

    &.clearScheduleFilters {
      outline: none;
      border: none;
      background: none;
      text-decoration: underline;
      color: #658ec6;
      font-weight: 600;
      transition: color 0.3s ease;

      &:hover {
        color: darken(#658ec6, 6%);
      }
    }
  }
}

#addressesSelect {
  :global .select {
    &__control {
      &--is-focused {
        border-color: #1A3A5F;
      }
    }

    &__multi-value {
      background-color: #1A3A5F;
      &__remove {
        background-color: #1A3A5F;

        &:hover {
          background-color: darken(#1A3A5F, 10%);
        }
      }
    }

    &__option--is-focused {
      background-color: #1A3A5F;
      color: #ffffff;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          &:hover {
            background-color: #1A3A5F;
            color: #ffffff;
          }
        }
      }
    }
  }
}

#orderTypesSelect {
  :global .select {
    &__control {
      &--is-focused {
        border-color: #498ECC;
      }
    }

    &__multi-value {
      background-color: #498ECC;
      &__remove {
        background-color: #498ECC;

        &:hover {
          background-color: darken(#498ECC, 10%);
        }
      }
    }

    &__option--is-focused {
      background-color: #498ECC;
      color: #ffffff;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          &:hover {
            background-color: #498ECC;
            color: #ffffff;
          }
        }
      }
    }
  }
}
