.header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 1vw -5px;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin: 0 5px;
    padding: 0 20px;
    border-radius: 12px;
    font-size: calc(6px + 0.4vw);
    background-color: #658ec6;
    color: #ffffff;
  }
}

.icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}
