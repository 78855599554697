.fileUpload {
  position: relative;

  label {
    display: block;
  }

  input[type='file'] {
    visibility: hidden;
    position: absolute;
    left: -99999px;
    width: 0;
    height: 0;

    + label {
      display: inline-flex;
      width: 160px;
      height: 32px;
      // margin-left: 1rem;
      border-radius: 0.1875rem;
      background: #333;
      justify-content: center;
      align-items: center;
      font-size: 0.75rem;
      color: #ffffff;
      cursor: pointer;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.85;
      }

      + .fileInfo {
        display: inline-flex;
        margin-left: .5rem;

        .fileName {
          display: inline-block;
          max-width: 140px;
          margin-right: .25rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.isBusy {
  position: absolute;
  top: 16px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .75);
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  max-width: 180px;
  max-height: 180px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}