.Backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(117, 117, 117, 0.705);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Backdrop.hide {
  display: none;
}

.Modal {
  max-width: 700px;
  background-color: #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 30px 60px;
}

.Icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: bottom;
  margin-right: 5px;
}

.Title {
  color: #2B2B2B;
  font-weight: bold;
  font-size: 20px;
}


.Files {
  margin: 30px 0;
}

.Files > div {
  padding-bottom: 15px;
}

.Files a {
  text-decoration: underline;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.checkboxWrapper {
  margin-left: -15px;
}

.Button {
  color: #ffffff;
  margin-top: .5rem;
  width: 150px;
  text-transform: none;
  border: none;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  background-color: #2B2B2B;
  transition: 500ms all;
}

.Button:hover {
  border-color: #0062cc;
  background-color: #202F4A;
}

.Button:disabled {
  background-color: #adadad;
  color: #ffffff;
}

.checked {
  color: #5882C1 !important;
}
