.advancedOptions {
  position: relative;
  display: inline-block;

  .ellipsisButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 16px;
    padding: 0;
    border: 0;
    background: #ffffff;
    line-height: 0;

    &:active,
    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.75;
    }

    svg {
      width: 60%;

      path {
        fill: #303030;
      }
    }
  }
}

.menu {
  list-style: none;
  position: absolute;
  right: 0;
  margin: 5px 0 0;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 20;

  li {
    max-width: 300px;

    button {
      width: 100%;
      padding: 1em;
      border: 0;
      background: none;
      font-size: .875em;
      line-height: 1;
      text-align: left;
      white-space: nowrap;

      &:active,
      &:focus {
        outline: 0;
      }

      &:hover {
        background-color: #fbfbfb;
      }
    }
  }
}
