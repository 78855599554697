.customSelect {
  height: 2rem;
  padding: 0 0.5rem;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  color: #919191;
  transition: border-color 0.3s;

  &:disabled {
    border-color: #e3e3e3;
    background-color: #efefef;
    opacity: 1;
  }
}
