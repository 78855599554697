.forwardModal {
  @import 'src/components/common/Form/shared/style';

  > div {
    padding: 0;
  }

  .field {
    padding: 0;

    &.actions {
      margin-top: 1.5em;

      button {
        margin: 0;
      }
    }
  }
}
