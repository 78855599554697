.orderCardService {
  background-color: #ffffff;
  border: 1px solid #dfe0e2;
  border-radius: 8px;
  margin-top: 10px;
  max-width: 320px;
  width: 24%;

  @media (max-width: 920px) {
    width: 100%;
  }
  height: 340px;
  box-shadow: 0px 3.35px 30px 0px rgba(71, 71, 71, 0.07);
  padding: 0 !important;
  display: flex;
  flex-direction: column;

  .banner {
    height: 127px;
    min-height: 127px;
    background-size: 100%;
    background-repeat: no-repeat;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: row-reverse;

    .news {
      background-color: #ffbc40;
      border-top-right-radius: 4.22px;
      border-bottom-left-radius: 4.22px;
      font-size: 21px;
      font-weight: 600;
      height: 36px;
      width: 60%;
      display: flex;

      .text {
        margin: auto;
        text-transform: uppercase;

        .icon {
          width: 22px;
          height: 22px;
          margin-top: -4px;
          margin-right: 10px;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 0 8px 0px 8px;
    height: 100%;

    div:last-of-type {
      margin-top: auto !important;
    }

    .containerTitle {
      display: flex;
      margin-top: 5px;
      margin-bottom: 10px;

      .icon {
        flex: 0 0 30px;
        padding-top: 2px;

        svg {
          font-size: 30px;
        }

        .izee {
          width: 36px;
          height: 29px;
          margin-right: 10px;
        }

        .docusign {
          width: 35px;
          margin-right: 10px;
        }
      }

      .title {
        font-size: 24px;
        font-weight: 700;
      }
    }

    .description {
      color: #6b6f77;
      font-size: 14px;
      margin-bottom: 10px;
      text-align: left;
    }

    .containerLink {
      text-align: left;
      margin-bottom: 10px;

      .link {
        color: #00a2dd;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .containerButton {
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 10px;

      button,
      a {
        padding: 8px 12px;
        border: 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        width: 100%;
      }

      a {
        display: block;
        text-decoration: none;
      }

      .buttonYellow {
        background-color: #fdbc52;
        color: #2b313c;
      }

      .buttonBlue {
        background-color: #00bbdd;
        color: #ffffff;
      }

      .buttonLoading {
        background-color: gray;
        color: #ffffff;
      }
    }
  }

  @media (max-width: 1780px) {
    .container {
      .containerTitle {
        .icon {
          flex: 0 0 22px;

          svg {
            font-size: 22px;
          }
          .izee {
            width: 28px;
            height: 24px;
            margin-right: 10px;
          }
          .docusign {
            width: 28px;
            margin-right: 10px;
          }
        }

        .title {
          font-size: 20px;
          padding-top: 2px;
        }
      }

      .description {
        font-size: 13px;
      }

      .containerLink {
        .link {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 1570px) {
    .banner {
      .news {
        font-size: 16px;
      }
    }
    .container {
      .containerTitle {
        .icon {
          flex: 0 0 20px;

          svg {
            font-size: 20px;
          }
        }

        .title {
          font-size: 16px;
          padding-top: 2px;
        }
      }

      .description {
        font-size: 12px;
      }

      .containerLink {
        .link {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 920px) and (min-width: 765px) {
    .banner {
      .news {
        .text {
          font-size: 13px;
          .icon {
            margin-right: 2px;
          }
        }
      }
    }
    .container {
      .containerTitle {
        .icon {
          flex: 0 0 18px;

          svg {
            font-size: 18px;
          }
        }
        .title {
          font-size: 12px;
          padding-top: 6px;
        }
      }
      .description {
        font-size: 11px;
      }

      .containerLink {
        .link {
          font-size: 11px;
        }
      }
      .containerButton {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
