.permissionsDetails {
  @import 'src/components/common/Form/shared/style';
  @import 'src/components/common/Card/shared/style';

  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;

      .actions {
        margin: 2em -5px 0;
      }
    }

    .actions {
      display: flex;
      justify-content: center;

      a,
      button {
        width: 100px;
      }
    }
  }
}

.permissions {
  margin-top: 30px;
  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.permissionsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .permission {
    display: flex;
    align-items: center;
    width: 100%;
    margin: .75em 0;
    line-height: 1.2;
  }

  input[type=checkbox] {
    margin-right: .5em;
  }

  .empty {
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }
}
