.checkbox {
  width: 16px;
  height: 16px;

  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:before {
        transform: scale(1);
      }
    }

    &:disabled + label {
      opacity: .5;
      filter: grayscale(.25);
      cursor: not-allowed;
    }
  }

  label {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 2px solid #393939;
    border-radius: 3px;

    &:before {
      content: '';
      width: 8px;
      height: 8px;
      transform: scale(0);
      border-radius: 1px;
      background-color: #393939;
      transition: transform 0.3s;
    }
  }
}
