.container {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 8px 5px;
  margin: 0;
}

.Paper {
  background-color: #d5d5d5;
  border: 2px solid #d9d9d9;
}

.Paper.active {
  background-color: #ffffff;
}

.Paper.selected {
  border: 2px solid #959595;
  box-shadow: inset 0 0 0 1px #959595;
}

.action {
  text-align: center;
  width: 48px;
}

.details {
  flex-grow: 1;
  color: #858585;
  font-size: 12px;
  padding: 5px 10px;
}

.rowContainer {
  display: flex;
  margin-bottom: 5px;
}

.leftItem {
  flex-grow: 1;
  align-self: center;
}

.rightItem {
  color: inherit;
  align-self: center;

  &.flexible {
    display: flex;
    align-items: center;
  }
}

.details h2 {
  color: black;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
}

.details h2 svg {
  font-size: 30px;
}

.details i svg {
  font-size: 22px;
  vertical-align: bottom;
  margin: 0px 5px;
}

.Status {
  color: rgb(255, 255, 255);
  background-color: rgb(133, 133, 133);
  border-radius: 15px;
  padding: 2px 3px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
}

.tagList {
  display: flex;
  gap: 4px;

  .tagItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 18px;
    padding: 0 10px;
    border-radius: 9px;
    background-color: #388acf;
    font-size: 0.5625rem;
    color: #ffffff;
  }
}
