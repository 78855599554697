.orderModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 100;
  overflow: auto;

  .content {
    position: absolute;
    top: 5%;
    left: 50%;
    min-width: 60vw;
    min-height: 25vh;
    border-radius: 5px;
    padding: 1.5rem 1.75rem;

    box-shadow: -3px 1px 30px rgba(0, 0, 0, 0.35);
    background-color: #fff;
    transform: translateX(-50%);

    .isEditingOrderBadge {
      position: absolute;
      background: #ff0000;
      padding: 2px 6px;
      top: -1px;
      right: -1px;
      border-radius: 0 5px 0 5px;
      color: #ffffff;
      font-size: 0.75rem;
      font-weight: 400;
    }

    &.isEditingOrderBorder {
      border: 3px solid #ff0000;
    }
  }

  .spinnerWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 12px;
    border-bottom: 2px solid #a5a5a5;
    color: #393939;

    .text {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  .body {
    display: flex;
    padding: 2.4em 0 0;

    .info,
    .form {
      position: relative;
      flex: 1;

      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child {
        margin-left: 0.5em;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .type {
      display: flex;
      align-items: center;
      margin-bottom: 1em;

      .icon {
        height: 28px;
        margin-right: 1em;
        line-height: 0;

        img {
          max-height: 100%;
        }
      }

      .text {
        font-size: 0.9375em;
        font-weight: 700;
        line-height: 1;
        color: #393939;
      }
    }

    .line {
      display: block;
      margin-top: 0.5em;
      font-size: 0.8125em;
      font-weight: 500;
      color: #919191;

      &:first-of-type {
        margin-top: 0;
      }

      strong {
        font-weight: 600;
      }
    }

    .accompaniedData {
      @extend .line;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 3px;

      > p {
        position: relative;
        display: inline-block;
        margin: 2px 0;
        padding-top: 0;
        padding-left: 16px;
        outline: none;

        &:before {
          content: '';
          background: #393939;
          position: absolute;
          top: 0;
          left: 10px;
          top: 56%;
          transform: translate(-50%, -50%);
          height: 4px;
          width: 4px;
          border-radius: 50%;
        }
      }
    }

    .attachments {
      @extend .line;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 3px;

      > button {
        position: relative;
        display: inline-block;
        padding-top: 0;
        padding-left: 16px;
        border: none;
        background: none;
        outline: none;
        color: #0056b3;
        text-decoration: underline;

        &:before {
          content: '';
          background: #393939;
          position: absolute;
          top: 0;
          left: 10px;
          top: 56%;
          transform: translate(-50%, -50%);
          height: 4px;
          width: 4px;
          border-radius: 50%;
        }
      }
    }

    .details {
      max-height: 200px;
      padding: 0.5em 1em;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      background-color: #fafafa;
      white-space: pre-wrap;
      overflow-y: auto;
    }

    .detailsCopy {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
      color: inherit;
      text-decoration: underline;
      border: none;
      background: none;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }

      img {
        height: 10px;
      }
    }

    .delete {
      display: flex;
      align-items: center;
      margin-top: auto;

      .link {
        display: flex;
        align-items: center;
        padding: 0 0.25em 0 0;
        background: none;
        border: 0;
        font-size: 12px;
        font-weight: 700;
        color: #393939;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        .icon {
          position: relative;
          top: -1px;
          width: 22px;
          height: 22px;
          margin-right: 0.5em;
        }
      }
    }

    .confirmQuestion {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 700;

      .actions {
        display: flex;
        margin-left: 1em;

        button {
          width: 64px;
          height: 32px;
          padding: 0;
          border: 0;
          border-radius: 3px;
          background-color: #393939;
          font-size: 12px;
          font-weight: 700;
          color: #ffffff;

          &:last-child {
            margin-left: 0.5em;
          }
        }
      }
    }

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 10px;
      margin-bottom: 15px;
      border-bottom: 2px solid #eee;
    }

    .tabMenu {
      text-align: center;
      width: 116px;
      font-size: 11px;
      border-bottom: 4px solid transparent;

      &.active {
        border-bottom: 4px solid #5292cd;
        font-weight: 600;
      }
    }

    .historic {
      max-height: 330px;
      overflow-y: auto;
      width: 100%;
    }
  }

  .orderLinkButton {
    background: none;
    border: none;
    text-decoration: underline;
    color: #5292cd;
  }
}

.whatsapp {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  color: #0485be;
}
