.dateInfoContainer {
  display: flex;
}

.dateInforDisplay {
  background-color: #1A3A60;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 600;
  padding: 4px 12px;
  text-align: center;
  width: 150px;
}
