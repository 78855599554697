.ContainerWrapper {
  overflow: hidden;
  height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  overflow: auto;
}

.ContentWrapper {
  overflow: auto;
  height: 100%;
}

.ContainerWrapperWithToolbar {
  overflow: auto;
  height: calc(100% - 65px);
}

.ContainerWrapperWithFiltersBar {
  overflow: auto;
  height: calc(100% - 128px);
}
