.tagModal {
  min-width: 375px;
  padding: 25px;

  h3 {
    font-size: 14px;
    font-weight: 700;
    color: #555555;
  }

  input {
    width: 100%;
  }

  button[type=submit] {
    display: block;
    height: 38px;
    margin: 10px 0 0 auto;
    padding: 0 20px 2px;
    border: 0;
    border-radius: 3px;
    background-color: #658ec6;
    font-size: 14px;
    color: #ffffff;
    transition: background-color .3s ease;

    &:hover {
      background-color: darken(#658ec6, 6%);
    }

    &:active,
    &:focus {
      outline: 0;
    }
  }
}
