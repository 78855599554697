.name {
  display: flex;
  align-items: center;

  .text {
    position: relative;
    top: 3px;
    font-size: 0.875em;
    line-height: 1;
    font-weight: 700;
  }

  .icon {
    position: relative;
    width: 2em;
    margin-right: 0.5em;
    color: #45668c;
    line-height: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.invalidAddress {
  position: relative;
  top: 3px;
  display: flex;
  align-items: center;
  margin-left: .875em;
  font-size: 0.875em;
  line-height: 12px;
  color: #b92d2f;

  img {
    height: 12px;
    margin-right: .3em;
  }
}

.line {
  display: flex;
  margin-top: .5em;
  font-size: 0.8125em;
  color: #919191;

  .phone {
    font-weight: 700;
  }

  .email {
    font-weight: 600;
  }

  > div:nth-child(2) {
    &:before {
      content: '•';
      margin: 0 5px;
    }
  }
}

.totalBalance {
  position: absolute;
  top: 50%;
  right: 1.625em;
  transform: translateY(-50%);
  min-width: 92px;
  padding: 1.125em;
  border: 2px solid #e3e3e3;
  border-radius: 8px;
  line-height: 1;
  text-align: center;
  color: #919191;

  .title {
    font-size: 0.625em;
    font-weight: 500;
  }

  .number {
    margin-top: 0.3125em;
    font-size: 1.25em;
    font-weight: 700;
  }
}
