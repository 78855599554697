.header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.information {
  color: #858585;
  font-size: calc(6px + 0.4vw);
  margin-top: 1vw;
  margin-bottom: 1vw;
  padding-left: calc(14px + 1.8vw);
}

.subHeader {
  font-weight: bold;
}

.icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}

.limit {
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.imageWrapper {
  display: flex;
  margin-top: .2rem;
  max-width: 150px;
  max-height: 150px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}