.headerMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;

  .movideskButton {
    margin: 0 10px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-left: 0.5em;
    border-radius: 50%;
    background-color: #4c8fc9;
    font-weight: 700;
    color: #ffffff;
    transition: background-color 0.3s linear;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: lighten(#4c8fc9, 10%);
      color: #fff;
    }

    &.faqLink {
      margin-right: 0.5em;
    }

    img {
      max-height: 22px;
    }
  }

  button {
    display: flex;
    align-items: center;
    height: 36px;
    margin-left: 0.5em;
    padding: 0 4px 0 18px;
    border: 0;
    background-color: #1f4774;
    border-radius: 18px;
    transition: background-color 0.3s linear;
    color: #ffffff;

    &:hover {
      background-color: #265182;
    }

    &:active,
    &:focus {
      outline: 0;
    }

    .name {
      font-family: 'Open Sans';
      font-size: 0.75em;
      font-weight: 700;
      line-height: 1.2;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin-left: 0.2em;
      border-radius: 50%;
      line-height: 0;

      svg {
        font-size: 36px;
        border-radius: 50%;
      }
    }
  }
}

.longMenu {
  z-index: 99999999 !important;
}
