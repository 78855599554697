.Button {
    color: #ffffff;
    margin: 30px 5px;
    width: 150px;
    text-transform: none;
    border: none;
    font-weight: lighter;
    padding: 8px;
    border-radius: 2px;
}

.Button.primary {
    background-color: #859905;
}

.Button.secondary {
    background-color: #BE1104;
}

.Button:disabled {
    opacity: 0.4;
}

.Spinner {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -10px;
}

.hr {
    display: block;
    border: 0;
    border-top: 1px solid #E9E9E9;
    padding: 0;
    margin: 0;
}

.sticky {
    display: block;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    padding-left: 1vw;
    padding-right: 1vw;
}

.notification {
    margin: 10px 10px;
}