.DocuSignFile {
  display: flex;
  flex-direction: row;
  padding-right: 0.5rem;
}

.DocuSignFile ul {
  list-style-type: none;
  padding-inline-start: 0px;
  width: 100%;
}

.DocuSignFile ul li .deleteButton {
  height: 24px;
  width: 24px;
  padding: 0;
  border: 0;
  background-color: #ffffff;
  box-shadow: none;
  color: #5882c1;
  transition: all 0.5s;
  cursor: pointer;
}

.DocuSignFile ul li .deleteButton:active,
.DocuSignFile ul li .deleteButton:focus {
  outline: 0;
}

.DocuSignFile ul li .deleteButton:disabled {
  background-color: #ffffff;
  color: #cce2f5;
  outline: none;
  cursor: default;
}

.DocuSignFile .dropZone {
  width: 100%;
  text-align: center;
  padding: 20px;
  border: 2px dashed #777;
  color: #777;
  font-size: 15px;
  font-weight: 600;
  background-color: #f0f0f0;
}

.DocuSignFile .addFile {
  background-color: #353535;
  color: #ffffff;
  padding: 0 2rem;
  text-transform: none;
  margin-right: 1rem;
  outline: none;
}

.DocuSignFile label {
  font-family: 'Open Sans';
}

.DocuSignFile .addFile:hover {
  background-color: #202f4a;
  border-color: #0062cc;
}

.DocuSignFile .title {
  margin-bottom: 0.6rem;
  font-weight: bold;
  font-size: calc(10px + 0.4rem);
  font-family: 'Open Sans';
}

.documentsSpacing {
  padding-left: 0.5rem;
}

.fileList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
}

.fileTitle {
  display: flex;
  align-content: center;
}

.fileText {
  padding: 0;
  font-size: 14px;
}

.fileSpacing {
  margin: 0;
}
