.sortSwitcher {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: inherit;
  height: 28px;
  padding: 0 6px;
  z-index: 5;
  margin-top: -28px;
  background: #e0e0e0;

  h4 {
    margin: 0;

    font-weight: 600;
    font-size: 0.75rem;
    color: #000;
  }

  button {
    border: none;
    background: none;
    margin: 0;
    padding: 0;

    position: relative;
    font-weight: 600;
    font-size: 0.75rem;
    color: #000;
    padding-right: 16px;

    .arrowDown {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -3px;
      border: 5px solid #393939;
      border-right-color: transparent;
      border-bottom: 0;
      border-left-color: transparent;
      pointer-events: none;

      &.rotateArrow {
        transform: rotate(180deg);
      }
    }
  }
}
