.root {
  display: flex;
}

.reportOptions {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    margin: 0;
    padding: 0;
    border-radius: 4px;
    list-style: none;
    overflow: hidden;

  li {
    position: relative;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    color: #393939;
    padding: 0px 0px 0px 15px;
    margin-bottom: 8px;

    &.listTitle {
      border-bottom: 1px solid #E5E7EB;
      display: flex;
      padding: 12px 15px;
      margin-top: 10px;
    }

    &:first-child {
      margin-top: 0;
    }

    &.hasSubMenu {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -4px;
        border: 2px solid #393939;
        border-right: 0;
        border-bottom: 0;
        width: 10px;
        height: 10px;
        transform: rotate(135deg);
      }
    }

    &.isOld {
      &:after {
        content: 'Modelo antigo';
        position: absolute;
        right: 5px;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-50%);
        height: 18px;
        padding: 1px 8px;
        border-radius: 9px;
        border: 1px solid #e3e3e3;
        font-size: 8px;
        font-weight: 500;
        color: #393939;
        pointer-events: none;
      }
    }

    .goBack {
      background: none;
      border: 0;
      width: 20px;
      height: 18px;
      padding: 0;
      transition: opacity .3s;

      &:hover {
        opacity: .75;
      }

      &:active,
      &:focus {
        outline: 0;
      }

      &:after {
        content: '';
        position: relative;
        top: 1px;
        display: inline-block;
        border: 2px solid #393939;
        border-right: 0;
        border-bottom: 0;
        padding: 0 2px 2px 0;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 38px;
      padding: 0 8px;
      border: none;
      border-radius: 2px;
      background: none;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      text-align: left;
      color: #393939;

      &:hover {
        background-color: #f2f2f2;
      }

      &:active,
      &:focus {
        outline: 0;
      }
    }

    .reportIcon {
      margin-right: 5px;
      line-height: 0;

      svg {
        font-size: 18px;
      }
    }
    .reportIconDownload {
      line-height: 0;

      svg {
        margin-top: 3px;
        font-size: 20px;
      }
    }
  }
}

.position {
  z-index: 1999;
}
