// sizes
$header-min-height: 54px;
$header-height: 4.6875%;

$menu-width: 220px;
$menu-min-height: 568px;

$font-size: 14px; // <= 1439px
$font-size-lg: 14px; // >= 1440px

// colors
$blue: #1a3a5f;
$dark-blue: #143253;
$grey: #eee;
$dark-grey: #d8d8d8;
$text-light: #fff;
$text-dark: #393939;
