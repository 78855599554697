
.bootstrapInput, .bootstrapInputError, .label, .title {
  font-family: 'Open Sans';
}

.label {
  margin-top: .6rem;
  line-height: 1.2rem;
  font-size: calc(6px + .2rem);
}

.title {
  margin-bottom: .6rem;
  font-weight: bold;
  font-size: calc(10px + .4rem);
  font-family: "Open Sans";
}

.bootstrapInput, .bootstrapInputError {
  border-radius: 4px;
  padding: 8px 12px;
  font-size: calc(6px + .6rem);
  width: calc(100% - 24px);
}

.bootstrapInput:focus, .bootstrapInputError:focus {
  border-color: #80bdff;
}

.bootstrapInput {
  font-weight: normal;
  border: 1px solid #ced4da;
}

.bootstrapInputError {
 border: 1px solid red;
}

.error {
  font-family: "Open Sans";
  font-weight: normal;
  color: #dc3545 !important;
  font-size: calc(6px + 0.3vw) !important;
  list-style-type: disc;
}

.plusButton {
  background-color: #5882C1;
  box-shadow: none;
  color: #ffffff;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  outline: none !important;
  align-self: center;
}

.grayLines {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left:   calc(2rem + 1.5rem);
  padding-right:  calc(15px + 1.5rem);
}
.grayLines:before,
.grayLines:after {
 background-color: #e9e9e9;
 content: "";
 display: inline-block;
 height: 1px;
 position: relative;
 vertical-align: middle;
 width: 50%;
}
.grayLines:before {
 right: 1.5em;
 margin-left: -50%;
}
.grayLines:after {
 left: 1.5em;
 margin-right: -50%;
}

.removeButton {
  background-color: #ffffff;
  box-shadow: none;
  color: #5882C1;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  outline: none !important;
  transition: all .5s;
}

.removeButton:focus, .removeButton:hover {
  background-color: #ffffff !important;
  filter: brightness(1.25);
}

.removeButtonDisabled {
  background-color: #ffffff !important;
  color: #cce2f5 !important;
  outline: none !important;
}

.flexHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signersSpacing {
  padding-left: .5rem;
}

.rootContainer {
  padding-right: .5rem;
}
