.reportTypeSelectOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: #80bdff;

    span {
      color: #fff;
    }
  }

  input {
    width: 12px !important;
    height: 12px;
    cursor: pointer;
  }

  span {
    font-weight: normal;
    font-size: 0.625rem;
  }
}
