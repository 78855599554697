.HeaderOrderPage {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.Header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #919191;
  font-size: 0.8125em;
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.Information {
  color: #858585;
  font-size: calc(6px + 0.4vw);
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.InformationOrderPage {
  color: #858585;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.5vw;
  margin-bottom: 1vw;
  padding-left: calc(14px + 1.8vw);
}

.SubHeader {
  font-weight: bold;
}

.Icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}

.editButton {
  padding: 0;
  border: none;
  background: #ffffff;
  line-height: 0;
}

.date {
  font-size: calc(6px + 0.4vw);
  text-align: right;
  color: #A5A5A5;
  margin-top: 0.5vw;
  margin-bottom: 0;
  font-weight: bold;
}

.annotation {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  textarea {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 120px;
    padding: 5px;
  }

}
