@import "common";

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: -1.5em;
  margin: -1.5em -1em -1em;
  padding: 2.5em 1em 1em;
  background-color: #eeeeee;
  z-index: 50;
}

.action {
  flex: 0 0 195px;
  margin-left: 1em;
  line-height: 1;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    padding: 8px;
    width: 100%;
    border: 0;
    border-radius: 3px;
    background-color: #4c8fc9;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    transition: opacity 0.3s;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    >img {
      height: 14px;
    }

    .spinnerWrapper {
      width: 14px;
      height: 14px;
      margin: 0 auto;

      svg {
        margin: 0;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.pendingOrdersWrapper {
  flex: 1;
  padding: 0.857142857em 0;
  border: $border-width solid #dbdbdb;
  border-radius: 3px;
  overflow-x: auto;
}

.draftBorder {
  position: fixed;
  background-color: #7a7a7a;
  line-height: 1;
  z-index: 100;

  &.isTop {
    transition: all 300ms;
    top: 54px;
    left: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 220px);
    height: 24px;
    color: #ffffff;

    &.sidebarCollapsed {
      transition: all 300ms;
      left: 70px;
      width: calc(100% - 70px);
    }
  }

  &.isRight {
    top: 54px;
    right: 0;
    width: 5px;
    height: calc(100% - 54px);
  }

  &.isBottom {
    bottom: 0;
    left: 220px;
    width: calc(100% - 220px);
    height: 5px;
  }

  &.isLeft {
    top: 54px;
    left: 220px;
    width: 5px;
    height: calc(100% - 54px);
  }
}

.pageDisabled {
  transition: all 300ms;
  position: fixed;
  top: 54px;
  right: 0;
  bottom: 0;
  left: 220px;
  background-color: rgba(255, 255, 255, .85);
  z-index: 100;

  .draftBorder {
    background-color: #aa5a5a;
  }

  &.sidebarCollapsed {
    transition: all 300ms;
    left: 70px;
  }
}

.dateContainer {
  display: flex;
  gap: 10px;
}
