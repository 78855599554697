.toast {
  position: fixed;
  right: 1.5em;
  bottom: 1.5em;
  display: flex;
  padding: 1.25em 1.5em 1.1em;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: #393939;
  z-index: 10001;
  transition: opacity 0.3s, transform 0.3s ease;

  &:hover {
    color: #393939;
    text-decoration: none;
  }
}

.closeButton {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  line-height: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.75;
  }
}

.icon {
  margin-top: 2px;
  width: 26px;
  height: 26px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-left: 1em;
}

.title {
  margin: 0;
  font-size: 0.875em;
  font-weight: 700;
}

.text {
  margin: 0;
  font-size: 0.875em;
  line-height: 1.25;
}

:global {
  .toast-enter {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  .toast-enter-active {
    opacity: 1;
  }

  .toast-exit {
    opacity: 1;
  }

  .toast-exit-active {
    opacity: 0;
  }
}
