.deleteAttachment {
  @import 'src/components/common/Form/shared/style';

  .field {
    padding: 0;

    &.actions {
      margin-top: 1.5em;
    }
  }

  textarea {
    resize: none;
  }
}

.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  line-height: 0;
  background: none;
  transition: opacity .3s;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .75;
  }

  svg {
    width: 14px;

    path {
      fill: #303030;
    }
  }
}