.contentModal {
  max-width: 700px;
}

.headerModal {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  gap: 5px;

  svg {
    font-size: 18px;
  }
}

.bodyModal {
  font-size: 14px;

  .green {
    color: #98BE15;
  }

  .blue {
    color: #0485BE;
  }
}

.listConflicts {
  margin-top: 40px;
}

.itensConflicts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;

  .grow1 {
    flex-grow: 1;
    flex-basis: 150px;
  }

  .arrowRight {
    flex-grow: 0;
    flex-basis: 100px;
  }

}

.actionsConflicts {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  flex-basis: 140px;
}

.actionsButton {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
