.pendingOrders {
  position: relative;
  height: 100%;
  width: 282px;

  .spinner {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    width: 100%;
  }

  ul {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    gap: .75em;
    list-style: none;
    margin: 0;
    padding: 0 8px 0 0;
    height: calc(100vh - 186px);
    overflow-y: auto;

    &.isDeactivated {
      pointer-events: none;
    }

    li {
      margin: 0;
      line-height: 0;

      &:first-of-type {
        margin-top: 6px;
      }
    }
  }
}
