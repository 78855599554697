@import 'src/scss/shared/variables';

.sideMenu {
  transition: all 300ms;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  flex: 0 0 $menu-width;
  flex-shrink: 0;
  min-width: $menu-width;
  max-width: $menu-width;
  min-height: 330px;
  padding: 1.55rem 1.15rem 1.15rem;
  background-color: $blue;
  color: $text-light;

  h3 {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    font-weight: 700;
    color: #5578a0;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 32px;
      // height: 32px;
      margin-right: 0.5em;
      // border: 1px solid #5578a0;
      border-radius: 50%;

      svg {
        font-size: 34px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;
      margin: 0 0 0 auto;
      padding: 0;

      .action {
        a {
          display: block;
          width: 24px;
          height: 24px;
          transition: opacity .3s;

          &:hover {
            opacity: .75;
          }

          &:active,
          &:focus {
            outline: 0;
          }

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;

    &.isSecondary {
      margin: auto -1.15rem 0;

      li {
        position: relative;
        width: 100%;

        a {
          margin: 0;
          padding: 0 1.15em;
          border: 0;
          font-size: 1.1em;
          font-weight: 700;
          color: #5578a0;

          .icon {
            position: relative;
            margin-right: 0.5em;
            width: 34px;
            height: 34px;

            svg {
              font-size: 34px;
            }
          }
        }
      }
    }

    li {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: flex;
        align-items: center;
        height: 3em;
        margin: 0 -1.15rem;
        padding: 0 1.15rem;
        background: none;
        border: 0;
        border-left: 4px solid transparent;
        line-height: 1;
        text-decoration: none;
        color: #ffffff;
        transition: all 0.3s;

        &:hover {
          background-color: darken(#1a3a5f, 2%);
        }

        &.isActive {
          border-left: 4px solid #ffffff;
          background-color: #153253;

          &:hover {
            background-color: darken(#153253, 2%);
          }
        }

        .icon {
          width: 28px;
          height: 28px;
          margin: 0 0.75em 0 0;
          line-height: 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.divergenceCount {
  position: absolute;
  top: 3px;
  left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9e3c5b;
  line-height: 1;
  font-size: 12px;
  color: #fff;
  pointer-events: none;
  z-index: 2;
}

.sideMenu.sidebarCollapsed {
  transition: all 300ms;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  min-height: 330px;
  padding: 1.55rem 1.15rem 1.15rem;
  background-color: $blue;
  color: $text-light;
  flex: 0 0 70px;
  min-width: 70px;
  max-width: 70px;

  h3 {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    font-weight: 700;
    color: transparent;

    .actions {
      display: none;
      justify-content: center;
      align-items: center;
      margin-right: 0.5em;
      border-radius: 50%;
    }
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;

    &.isSecondary {
      display: block;
      margin: auto -1.15rem 0;

      li {
        position: relative;
        width: 100%;

        a {
          margin: 0;
          padding: 0 1.15em;
          border: 0;
          font-size: 1.1em;
          font-weight: 700;
          color: transparent;

          .icon {
            display: flex;
            position: relative;
            margin-right: 0.5em;
            width: 34px;
            height: 34px;

            svg {
              font-size: 34px;
            }
          }
        }
      }
    }
  }
}

.toggleSidebar {
  position: absolute;
  top: 50%;
  right: -10px;
  border: none;
  border-radius: 50%;
  padding: 0px;
  width: 35px;
  height: 35px;
  background-color: #143253;
  z-index: 10;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
