.inspectorsContainer {
  width: 100%;
  background-color: #fff;
}

.header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.headerModal {
  font-weight: bold;
  font-size: calc(10px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin: 0 calc(1vw + 1px) 0 1px;
}

.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 8px;
  color: #858585;

  .title,
  .line {
    >div {
      display: flex;
      align-items: center;
      // flex: 0 0 33.333333%;
      // width: 33.333333%;

      &:nth-child(3n) {
        width: 100px;
        flex: 0 0 100px;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 1em;
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
  }

  .line {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    flex: 0 0 100%;
    font-size: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 10px;
      height: calc(100% - 1px);
      border-left: 1px dotted #858585;
      z-index: 1;
    }

    &:last-child {
      padding-bottom: 0 !important;
    }

    .action {
      display: flex;
      align-items: center;
    }

    .actionIcon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21px;
      height: 21px;
      border: 2px solid #fff;
      border-radius: 50%;
      line-height: 1;
      background-color: #ffffff;
      color: #fff;
      z-index: 2;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 2;
      }
    }

    .actionName {
      margin-left: .5em;
    }
  }

  .lineObservation {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 4em;
    flex: 0 0 100%;
    font-size: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 10px;
      height: calc(100% - 1px);
      border-left: 1px dotted #858585;
      z-index: 1;
    }

    &:last-child {
      padding-bottom: 0;

      &:before {
        border-left: none;
      }
    }

    .action {
      display: flex;
      align-items: center;
    }

    .actionName {
      margin-left: .5em;
    }

    .observationIcon {
      margin-top: -10px;
      margin-left: 10px;
      margin-right: 12px;
      background: #858585;
      height: 12px;
      position: relative;
      width: 1px;
    }

    .observationIcon:after {
      background: #858585;
      content: "";
      height: 1px;
      left: 0px;
      position: absolute;
      top: 12px;
      width: 15px;
    }
  }
}

.link {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  color: #007BFF;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

p .link {
  margin: 5px 0px;
  padding-left: calc(14px + 1.8vw);
}

.addInspectorInput {
  display: none;
}

.buttonIcon {
  vertical-align: middle;
  margin-right: 5px;
}

.advancedOptions {
  position: relative;
  display: inline-block;

  .ellipsisButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 16px;
    padding: 0;
    border: 0;
    background: #ffffff;
    line-height: 0;

    &:active,
    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.75;
    }

    svg {
      width: 60%;

      path {
        fill: #303030;
      }
    }
  }
}

.menu {
  list-style: none;
  position: absolute;
  right: 0;
  margin: 5px 0 0;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 20;

  li {
    max-width: 250px;

    button {
      width: 100%;
      padding: 1em;
      border: 0;
      background: none;
      font-size: 12px;
      line-height: 1;
      text-align: left;
      white-space: nowrap;

      &:active,
      &:focus {
        outline: 0;
      }

      &:hover {
        background-color: #fbfbfb;
      }
    }
  }
}

.modalHeader {
  font-size: 14px;
}

.observation {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  textarea {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 100px;
    padding: 5px;
  }
}
