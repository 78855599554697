.container {
  font-weight: bold;
  align-items: center;
  height: 100%;
}

.saveButton {
  background-color: #909b11;
  color: #ffffff;
  font-size: calc(6px + 0.4vw);
  text-transform: none;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 9vw;
}

.saveButton:hover {
  background-color: #202f4a;
  border-color: #0062cc;
}

.cancelButton {
  background-color: #a50c00;
  color: #ffffff;
  font-size: calc(6px + 0.4vw);
  text-transform: none;
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 9vw;
}

.cancelButton:hover {
  background-color: #202f4a;
  border-color: #0062cc;
}

.form {
  font-weight: bold;
  font-size: calc(6px + 0.3vw);
  padding: 0 20px;
  width: 100%;
}

.contentWrapper {
padding: 50px 0;
}
