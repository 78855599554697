@import 'src/components/common/Card/shared/style';

.repairBudgetForm {
  @import 'src/components/common/Form/shared/style';

  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 1em;
  font-size: 14px;

  &.isClientPanel {
    margin-top: -20px;

    .card {
      padding: 1.75em 0.7em;
      border: 0;
    }
  }

  .field {
    padding: 0;
  }

  p {
    margin: 0;
    line-height: 1.2;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #393939;

    &:after {
      content: '';
      position: relative;
      top: 2px;
      flex: 1;
      width: 100%;
      height: 1px;
      margin-left: 1rem;
      background-color: #eeeeee;
    }
  }

  .headActions {
    margin-bottom: 1em;
    padding-bottom: 1em;
  }
}

.cardHead {
  margin-right: -1.325em;
  margin-left: -1.325em;
  padding-right: 1.325em;
  padding-left: 1.325em;
}

.damageList {
  display: flex;
  flex-direction: column;

  .room {
    margin: 1rem 0;
    font-size: 14px;
    font-weight: 500;
    color: #919191;
  }

  .damage {
    margin: 1rem 0 0;

    &:first-of-type {
      margin: 0;
    }
  }
}

.borderTop {
  border-top: 1px solid #eee;
  padding-top: 1.4em;
}

.isCentered {
  text-align: center;
}

.select {
  :global .select {
    &__control {
      &--is-focused {
        box-shadow: none;
      }
    }

    &__placeholder {
      font-size: 12px;
    }

    &__input {
      font-size: 12px;
    }

    &__single-value {
      font-size: 12px;
    }

    &__multi-value {
      border-radius: 15px;
      background-color: #658ec6;
      color: #ffffff;

      &__label {
        padding: 2px 2px 2px 8px;
        font-size: 12px;
        color: #ffffff;
      }

      &__remove {
        border-radius: 0 13px 13px 0;
        padding: 2px 5px 2px 2px;
        transition: background-color 0.3s;
        background-color: #658ec6;

        &:hover {
          color: #ffffff;
          background-color: darken(#658ec6, 10%);
        }
      }
    }

    &__value-container {
      [class$='Input'] {
        padding: 0;
        line-height: 0;
      }

      .select__input {
        padding: 4px 0;
        height: 24px;

        input {
          height: 100%;
        }
      }
    }

    &__value-container--is-multi {
      flex-wrap: wrap;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          font-size: 12px;
        }
      }
    }
  }
}
