.Dialog {
    max-width: 700px;
    padding: 50px 10px 10px;
}

.ImageWrapper {
    align-self: end;
}

.ImageWrapper img {
    padding: 10px;
    width: 100%;
}


.ContentWrapper {
    padding-bottom: 0;
}

.ContentWrapper a {
    display: inline-block;
    color: #949494;
    text-decoration: underline;
}

.Dialog button {
    font-weight: bold;
    color: #5A8192;
    font-size: 22px;
}