.clientDetails {
  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.permissionList {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    display: block;
    align-items: center;
    width: calc(50% - 5px);
    height: 24px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    input[type=checkbox] {
      margin-right: .33rem;
      vertical-align: middle;
    }
  }
}