.Button {
  color: #ffffff;
  margin: 30px 5px;
  width: 150px;
  text-transform: none;
  border: none;
  font-weight: lighter;
  padding: 8px;
  border-radius: 2px;
}

.newButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 196px;
  height: 36px;
  margin: 30px 0;
  padding: 0;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #393939;

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &:hover {
      opacity: 0.75;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &.isResync {
    width: 144px;
  }

  &.isSuccess {
    background-color: #ececec;
    border-color: #ececec;
    color: #c5c5c5;

    span {
      display: none;
    }

    &.isResend {
      &:after {
        content: 'Envelope enviado'
      }
    }

    &.isResync {
      &:after {
        content: 'Sincronizado'
      }
    }

    svg {
      path {
        fill: #c5c5c5;
      }
    }
  }

  .Spinner {
    margin-top: -10px;
  }

  svg {
      margin-right: 5px;
  }
}

.disabled {
  opacity: 0.4;
  color: #fff;
}

.primary {
  background-color: #859905;
}

.secondary {
  background-color: #be1104;
}

.hr {
  display: block;
  border: 0;
  border-top: 1px solid #e9e9e9;
  padding: 0;
  margin: 0;
}

.sticky {
  display: block;
  position: sticky;
  bottom: -20px;
  width: 100%;
  text-align: center;
  background-color: white;
  z-index: 3;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -.5em;
  padding: 2em 0;

  button {
    margin: 0 .5em;
  }
}

.Spinner {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -10px;

  svg {
    margin: 0;
  }
}
