/**
* @prettier
**/
.Header,
.Information,
.Icon {
  font-family: "Open Sans";
}

.Header {
  font-weight: bold;
  font-size: calc(6px + 0.3rem);
  margin: 0.5rem 0;
}
.Header::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  border-top: 1px solid #e1e0e0;
  width: calc(100% - 5rem);
  left: 78px;
  bottom: 8px;
}

.Information {
  color: #858585;
  font-size: calc(6px + 0.3rem);
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
.Information p {
  padding: 0.5rem 0;
}

.Icon {
  font-size: calc(14px + 0.56rem);
  margin-top: 0.3rem;
  margin-right: 0.9rem;
}

* > .signersSection:last-child {
  min-height: 20rem;
  flex-wrap: nowrap;
}
.signersSection:not(:last-child) > div > div:last-child .SignersContainer {
  border-bottom: 1px solid #e1e0e0;
}
.signersSection {
  flex-wrap: nowrap;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.SignersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StatusIcon {
  height: calc(18px + 3rem);
  width: calc(15px + 3rem);
  margin-bottom: 1.5rem;
  margin-top: 0.1rem;
}
