.form {
  :global {
    .array-field-template {
      .array-item {
        display: flex;

        > .field {
          flex-direction: row !important;
          align-items: center !important;
          flex: 1;
        }
      }

      .object-field-template {
        flex: 1;
        margin: 0 .625rem !important;

        &:first-child {
          flex: 0;
          margin-left: 0 !important;
        }

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .object-field-template {
      margin: 1.25em 0 0;

      &:first-child {
        margin: 0;
      }

      .field {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        font-weight: 500;
        color: #919191;

        &.hasRadio {
          display: flex;
          flex-direction: row;
          margin: 1.4rem 0 2rem;

          .radio {
            &:not(:first-child) {
              margin-left: 2rem;
            }
          }
        }

        &.isFlex {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .field {
            margin: 0;
            padding: 0;
            font-size: 1rem;

            $gap: 10px;
            @for $i from 1 through 12 {
              $multiplier: $i / 12;
              &.is#{$i} {
                width: calc(100% * #{$multiplier} - #{$gap} * (1 - #{$multiplier}));
              }
            }
          }
        }

        .label {
          margin: 0;
          font-size: 0.75rem;
          font-weight: 700;
          text-transform: uppercase;
          color: #393939;
        }

        .value {
          margin: 0.4rem 0 0;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.15;
        }
      }
    }

    .helper {
      margin-top: 0.4rem;
      font-size: 0.6875rem;
      color: #999;
    }

    label {
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #393939;
    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    textarea {
      height: 2rem;
      padding: 0 0.5rem;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      font-family: 'Open Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 500;
      color: #919191;
      transition: border-color 0.3s;

      &:focus,
      &:active {
        outline: 0;
        border-color: #4d8fca;
      }

      &[disabled] {
        background-color: #efefef;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: 400;
        color: #b1b1b1;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: 400;
        color: #b1b1b1;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-weight: 400;
        color: #b1b1b1;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-weight: 400;
        color: #b1b1b1;
      }
    }

    textarea {
      height: 6rem;
      padding: 0.8rem 0.5rem;
      line-height: 1.3;
      resize: vertical;
    }

    .radio {
      display: flex;
      align-items: center;

      label {
        margin: 0;
      }

      .radioInput {
        margin-right: 0.5rem;
      }
    }

    .button {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 140px;
      height: 36px;
      margin: 0 0.5rem;
      padding: 0 1.2rem;
      border: 0;
      border-radius: 3px;
      background: #c1c1c1;
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 1.4;
      color: #ffffff;
      transition: all 0.3s;

      &:hover {
        opacity: 0.75;
      }

      &:active,
      &:focus {
        outline: 0;
      }

      &[disabled] {
        cursor: not-allowed;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.8);
          z-index: 1;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -9px;
          margin-left: -9px;
          width: 18px;
          height: 18px;
          border: 2px solid #cccccc;
          border-right-color: #333333;
          border-radius: 50%;
          z-index: 2;
          animation: rotate 0.6s infinite linear;
        }
      }

      &.isGreen {
        background: #97a500;
      }

      .isOutlined {
        height: 32px;
        border: 1px solid #4c8fc9;
        border-radius: 50px;
        background: none;
        color: #4c8fc9;
      }
    }
  }
}
