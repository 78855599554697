@import 'src/scss/shared/variables';

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: $font-size;

  @media screen and (min-width: 1440px) {
    font-size: $font-size-lg;
  }

  .container {
    display: flex;
    flex: 1;

    .content {
      display: flex;
      flex: 1;
      margin: 0;
      padding: 0;
      background-color: $grey;
      color: $text-dark;
      overflow-y: auto;
      flex: 1;
    }
  }
}
