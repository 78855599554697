.ActionBoxWrapper {
    flex-grow: 0;
}

.ActionBoxWrapper button {
    margin: 0px 4px;
    /* color: #858585!important; */
    text-transform: none;
    padding: 10px 8px;
    font-weight: 600;
    font-size: 0.75rem;
    height: 42px;
}
.ActionBoxWrapper button:disabled img{
    opacity: 0.3;
}
