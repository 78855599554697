.Header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.Icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}

.ListWrapper {
  margin-bottom: 20px;
}

.ListWrapper ul {
  padding: 0 40px;
}

.ListWrapper > ul {
  padding: 0 45px;
}

.ListWrapper li {
  list-style-type: none;
}

.ListWrapper > ul p.VersionTitle {
  font-weight: bold;
}

.ListWrapper li p {
  display: flex;
  color: #858585;
  font-size: calc(6px + 0.4vw);
}

.ListWrapper li p span:not(:last-child) {
  flex-grow: 2;
}

.ShowMoreButton {
  width: 100px;
  margin: 0 auto;
  display: block;
  font-size: calc(6px + 0.4vw);
  border: 1px solid #3a79bf;
  color: #3a79bf;
  border-radius: 25px;
  padding: 5px 20px;
  font-weight: bold;
}

.ShowMoreButton:focus {
  outline: none;
  opacity: 0.9;
}

.ShowMoreButton:hover {
  opacity: 0.6;
}

.IntegrityCode {
  cursor: pointer;
}

.ListAction {
  display: flex;
}
