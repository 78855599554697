@import "../common";

.timeline {
  display: flex;
  width: 100%;
  min-height: 144px;
  margin-top: 3em;

  .leftListsContainer {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin: 0 20px 0 0;
    column-gap: 14px;
  }

  .contentWrapper {
    position: relative;
    display: flex;
    margin: 0 auto;
    z-index: 1;
    overflow-x: auto;
    overflow-y: hidden;
    flex: 1;
    width: 100%;
  }
}
