.ordersReportsModal {
  @import 'src/components/common/Form/shared/style';

  > div {
    padding: 0;
  }

  > p {
    font-size: 0.85rem;
  }

  .fieldWrapper {
    > label {
      margin-top: 1.5em;
    }

    > section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 16px;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }

      .field {
        margin: 0;
        width: 100%;
      }
    }
  }

  .field {
    padding: 0;

    &.actions {
      margin-top: 1.5em;

      button {
        margin: 0;
      }
    }
  }
}
