.dateSwitch {
  display: flex;
  align-items: center;

  .datePicker {
    position: relative;
    z-index: 15;
    background-color: #DCDCDC;
    border-radius: 4px;
    padding: 4px 12px;

    :global .react-datepicker-wrapper {
      width: 100%;
    }

    input {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100px;
      padding: 0;
      background: none;
      border: 0;
      font-size: 1.125em;
      font-weight: 700;
      text-align: center;
      color: #393939;
      cursor: pointer;

      &:active,
      &:focus {
        outline: 0;
      }
    }

    .arrowDown {
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -3px;
      border: 5px solid #393939;
      border-right-color: transparent;
      border-bottom: 0;
      border-left-color: transparent;
      pointer-events: none;
    }
  }

  .navigateBefore {
    cursor: pointer;
    margin-right: 4px;
  }

  .navigateNext {
    cursor: pointer;
    margin-left: 4px;
  }
}

:global .react-datepicker__today-button {
  background-color: #4c8fc9;
  color: white;
  border-top: 0px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
