.confirmAlert{
  @import 'src/components/common/Form/shared/style';

  * {
    font-family: 'Open Sans' sans-serif;
    text-transform: none;
    text-decoration: none;
  }

  button {
    color: #fff ;
    transition: opacity .3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .okButton {
    background-color: #859905;
  }

  .cancelButton {
    background-color: #c1c1c1;
  }
}
