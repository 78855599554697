.container {
  @import 'src/components/common/Form/shared/style';

  .field {
    padding: 0;

    &.actions {
      margin-top: 1.5em;
    }
  }
}

.Header {
  font-weight: bold;
  font-size: 18px;
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}
