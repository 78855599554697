.userForm {
  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  .field {
    margin: 1.25em 0 0;

    &:first-child {
      margin-top: 1.25em;
    }
  }
}

.permissionList {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    width: calc(50% - 5px);
    height: 24px;
    line-height: 1;
    word-break: break-all;

    input[type=checkbox] {
      margin-right: .33rem;
      vertical-align: middle;
    }

    label {
      display: block;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}