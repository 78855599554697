.orderForm {
  @import 'src/components/common/Form/shared/style';

  .field {
    padding: 0;
  }

  .button {
    height: auto;
    width: 100%;
    max-width: 120px;
    min-width: auto;
    padding: 6px 8px;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .error {
    margin: 0;
  }
}

.field {
  position: relative;
  padding: 0;

  textarea {
    resize: none;
  }

  :global .select {
    &__control {
      min-height: 0;
      height: 3em;
      font-size: 0.875em;
      border-color: #e3e3e3;
    }

    &__value-container {
      top: -2px;
    }
  }
}

.required {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.warning {
  margin: .5em 0 0;
  font-size: .87em;
  color: #ff0000;
}

.unavailable {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: .5em 0 0;

  >p {
    margin: 0;
    font-size: .87em;
    color: #ff0000;
  }
}

.status {
  color: rgb(255, 255, 255);
  background-color: #3288B9;
  font-size: 10px;
  border-radius: 15px;
  padding: 2px 3px;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  margin-top: .5em;
  display: flex;
  gap: 5px;
}
.alert{
  display: flex;
  justify-content: space-between;
  @media (max-width: 1300px) {
     flex-direction: column-reverse;
  }
}
