.container {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  max-width: 980px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  background-color: #153253;
  color: #ffffff;
}

.logo {
  flex: 0 0 33.3333333%;
  width: 33.3333333%;
  height: 35px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.credit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 33.3333333%;
  width: 33.3333333%;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;

  .creditIcon {
    line-height: 0;
  }

  .creditText {
    margin-left: .5em;
    line-height: 1;
  }
}

.right {
  flex: 0 0 33.3333333%;
  width: 33.3333333%;
  text-align: right;
}

.menu {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin: 0;
    padding: 0;
    background-color: #0e2744;
    list-style: none;

    li {
      line-height: 1;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 48px;
        text-decoration: none;
        font-size: .875em;
        color: #ffffff;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &:active,
        &:focus {
          outline: 0;
        }
      }
    }
  }
}
