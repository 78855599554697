@import "../../common";

.inspectorList {
  position: relative;
  height: 100%;
  width: 186px;

  header {
    position: fixed;
    display: flex;
    align-items: center;
    width: inherit;
    height: 28px;
    padding: 0 6px;
    z-index: 5;
    margin-top: -28px;
    background: #e0e0e0;

    h4 {
      margin: 0;

      font-weight: 600;
      font-size: 0.75rem;
      color: #000;
    }
  }

  > div {
    &:first-of-type {
      margin-top: 6px;
    }
  }

  .inspector {
    position: relative;
    width: 100%;
    height: 100px + $border-width * 2;
    padding: 15px 20px;
    border: $border-width solid #dbdbdb;
    border-left: 0;
    border-radius: 3px;
    background-color: #e8e8e8;
    line-height: 1;

    &:before {
      content: '';
      position: absolute;
      top: -$border-width;
      left: 0;
      bottom: -$border-width;
      width: 7px;
      background-color: #c2c2c2;
      border-radius: 3px 0 0 3px;
    }

    .name {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.2;
      text-overflow: ellipsis;
      color: #393939;
      white-space: nowrap;
      overflow: hidden;
    }

    .location {
      margin-top: .25em;
      font-size: 12px;
      line-height: 1.2;
      text-overflow: ellipsis;
      color: #919191;
      overflow: hidden;
      white-space: nowrap;
    }

    .warningMessage {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 6px;
      margin: 5px 0 0 0;
      font-size: 10px;
      border-radius: 22px;
      border: 1px solid #C4C4C4;
      background: #F5F5F5;
      transition: all .3s;

      > img {
        display: inline-block;
        margin-right: 6px;
        width: 18px;
        height: 18px;
      }

      > span {
        font-weight: 500;
        color: #1E1F22;
      }
    }
  }

  .spinnerWrapper {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    width: 100%;
  }
}
