.orderCardType {
  border: 1px solid #dfe0e2;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(43, 49, 60, 0.2);
  padding: 12px 8px;
  gap: 12px;
  width: 49%;
  min-height: 110px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: white;

  &:hover {
    border: 1px solid #0062cc;
  }

  .title {
    display: flex;

    h1 {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .icon {
    flex: 0 0 28px;
    padding-top: 2px;

    svg {
      font-size: 28px;
    }
  }

  .description {
    color: #6b6f77;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }

  .helpContainer {
    margin-top: -8px;
    width: 100%;
    height: 22px;
    text-align: right;
  }

  .help {
    width: 22px;
    height: 22px;

    img {
      max-height: 100%;
    }
  }

  @media (max-width: 770px) {
    .title {
      display: flex;

      h1 {
        font-size: 18px;
        font-weight: 700;
      }
    }

    .icon {
      flex: 0 0 24px;
      padding-top: 2px;

      svg {
        font-size: 24px;
      }
    }

    .description {
      font-size: 12px;
    }
  }
}

.noDescription {
  min-height: 36px;
  margin-bottom: 12px;
  padding: 8px 8px;

  

  .title {
    h1 {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
