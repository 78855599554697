.service {
  padding: 1em 0;
  width: 100%;

  hr {
    width: 100%;
    height: 1px;
    margin: 2em 0;
    border: 0;
    background-color: #eeeeee;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding: 2em 0;
}

.info {
  display: flex;

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    padding: 0;
    border: 2px solid #e3e3e3;
    border-radius: 3px;
    background: none;
    font-size: .875em;
    font-weight: 600;
    text-align: center;
    overflow: hidden;

    img {
      width: 64px;
    }

    .flag {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      background-color: #e3e3e3;
      transform: rotate(45deg);

      img {
        position: absolute;
        left: 14px;
        bottom: 1px;
        width: 14px;
        transform: rotate(-40deg);
      }
    }
  }

  .data {
    margin-left: 2em;
    padding-top: .25em;

    .name {
      margin: 0;
      font-size: 1.25em;
      font-weight: 700;
      color: #393939;
    }

    .slogan {
      margin-top: .25em;
      font-size: .875em;
      color: #393939;
    }

    .status {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .disable {
        margin: .625rem 0 0 0;
        padding: 0;
        background: none;
        border: 0;
        font-size: .625rem;
        text-decoration: underline;
        color: #393939;
        cursor: pointer;

        &:active,
        &:focus {
          outline: 0;
        }

        &:hover {
          opacity: .75;
        }
      }
    }

    .isEnabled {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 2em;
      border-radius: 3px;
      background-color: #42924f;
      font-size: .75em;
      color: #ffffff;
    }
  }
}

.description {
  margin: 0;
  font-size: 0.875em;
  line-height: 1.4;
}

.formWrapper {
  margin-top: 1.25rem;
  max-width: 400px;
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  height: 2rem;
  margin: 1rem 0;
  padding: 0 1.2em;
  border: 0;
  border-radius: 3px;
  background: #393939;
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  transition: all 0.3s;

  &:hover {
    opacity: 0.75;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &[disabled] {
    cursor: not-allowed;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .8);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -9px;
      width: 18px;
      height: 18px;
      border: 2px solid #cccccc;
      border-right-color: #333333;
      border-radius: 50%;
      z-index: 2;
      animation: rotate .6s infinite linear;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
