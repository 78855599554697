@import '../../common';

.spinnerWrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  width: 100%;
}

.content {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  .hours {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 10;
    height: 28px;
    margin: -28px 0 0 -5px;
    background: #e0e0e0;
    overflow: hidden;

    &:before {
      content: '';
      flex: 0 0 6px;
      width: 6px;
      height: 30px;
      background-color: #e0e0e0;
    }

    &:after {
      content: '';
      flex: 0 0 28px;
      width: 20px;
      height: 30px;
      background-color: #e0e0e0;
    }

    .time {
      width: 150px;
      flex: 0 0 150px;
      font-size: 12px;
      font-weight: 600;
      color: #393939;
      z-index: 2;
    }
  }

  .orderList {
    position: relative;
    margin: 6px 0 0 16px;
    z-index: 4;
    background-image: linear-gradient(to right, #eeeeee 1px, transparent 1px),
      linear-gradient(to top, #eeeeee 1px, transparent 1px), linear-gradient(to right, #e3e3e3 1px, transparent 1px);
    background-size: 100% 1px, 1px 4px, 150px 1px;
    width: 2250px; // ajustado para intervalo de 05:00 a 19:00h (150px por hora)
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    .track {
      position: relative;
      flex: 1;
      height: 100px + $border-width * 2;
      border-radius: 3px;
      background-color: #f5f5f5;
      background-image: linear-gradient(to right, #f5f5f5 1px, transparent 1px),
        linear-gradient(to top, #f5f5f5 1px, transparent 1px), linear-gradient(to right, #e3e3e3 1px, transparent 1px);
      background-size: 100% 1px, 1px 4px, 150px 1px;
      user-select: none;
    }
  }
}

.inspectorAvailabilityInterval {
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 75px;
  height: 100%;
  user-select: none;
}

.availableInterval {
  @extend .inspectorAvailabilityInterval;

  background: transparent;
}

.unavailableInterval {
  @extend .inspectorAvailabilityInterval;

  background-color: #f4cb9b;
}

.busyInterval {
  @extend .inspectorAvailabilityInterval;

  background-image: linear-gradient(
    45deg,
    #ffffff 25%,
    #f6cfcf 25%,
    #f6cfcf 50%,
    #ffffff 50%,
    #ffffff 75%,
    #f6cfcf 75%,
    #f6cfcf 100%
  );
  background-size: 5px 5px;
}

.unavailabilityTooltip {
  background: #5292cd;
  width: 100%;
  max-width: 320px;
}

.block {
  display: block;
}
