.createUser {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';
}

.toastError > div {
  background-color: #BD362F;
  transition: all .3s;
}

.permissionsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px auto;
  text-align: center;
  padding: 0 46px;

  > div {
    display: flex;
    align-items: center;
    width: calc(50% - 5px);
    height: 24px;

    input[type=checkbox] {
      margin-right: .33rem;
      vertical-align: middle;
    }

    label {
      display: block;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #393939;
      word-break: break-all;
      line-height: 1;
    }
  }
}
