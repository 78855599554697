.accessDenied {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 758px;
  margin: 5% auto 0;
  padding: 5%;
  background: #ffffff;
}

.logo {
  width: calc(100% - 20px);
  max-width: 328px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.message {
  max-width: 450px;
  margin-top: 4em;
  font-size: 1em;
  text-align: center;
  color: #333333;
}