.Dropzone {
  background-color: #F0F0F0;
  border-style: dashed;
  border-color: #D1D1D1;
  border-width: 2px;
  padding: 40px;
}

.Dropzone .title {
  font-weight: bold;
  font-size: calc(10px + 0.3vw);
}

.FileButton {
  text-align: right;
}

.FileButton button {
  background-color: #353535;
  color: #FFFFFF;
  padding-right: 3vw;
  padding-left: 3vw;
  text-transform: none;
}

.FileButton button:hover   {
   background-color: #202F4A;
   border-color: #0062cc;
}

.UploadInstruction {
  text-align: left;
}

.UploadInstruction span {
  color: #BCBCBC;
  font-size: calc(8px + 0.3vw);
}
