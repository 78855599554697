.activityLog {
  width: 100%;
  background-color: #fff;
}

.header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin: 0 calc(1vw + 1px) 0 1px;
}

.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 8px;
  color: #858585;

  .title,
  .line {
    >div {
      display: flex;
      align-items: center;
      // flex: 0 0 33.333333%;
      // width: 33.333333%;

      &:nth-child(3n) {
        width: 100px;
        flex: 0 0 100px;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 1em;
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
  }

  .line {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    flex: 0 0 100%;
    font-size: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 10px;
      height: calc(100% - 1px);
      border-left: 1px dotted #858585;
      z-index: 1;
    }

    &:last-child {
      padding-bottom: 0 !important;
    }

    .action {
      display: flex;
      align-items: center;
    }

    .actionIcon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 21px;
      height: 21px;
      border: 2px solid #fff;
      border-radius: 50%;
      line-height: 1;
      background-color: #ffffff;
      color: #fff;
      z-index: 2;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 2;
      }
    }

    .actionName {
      margin-left: .5em;
    }
  }

  .lineObservation {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 4em;
    flex: 0 0 100%;
    font-size: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 10px;
      height: calc(100% - 1px);
      border-left: 1px dotted #858585;
      z-index: 1;
    }

    &:last-child {
      padding-bottom: 0;

      &:before {
        border-left: none;
      }
    }

    .action {
      display: flex;
      align-items: center;
    }

    .actionName {
      margin-left: .5em;
    }

    .observationIcon {
      margin-top: -10px;
      margin-left: 10px;
      margin-right: 12px;
      background: #858585;
      height: 12px;
      position: relative;
      width: 1px;
    }

    .observationIcon:after {
      background: #858585;
      content: "";
      height: 1px;
      left: 0px;
      position: absolute;
      top: 12px;
      width: 15px;
    }
  }
}
