@import '../common';

.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.orderCardTooltip {
  background: #5292cd;
  width: 100%;
  max-width: 320px;
}

.orderCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  width: 262px;
  height: 100%;
  max-height: 106px;
  padding: 4px 6px;
  border: $border-width solid #a5a5a5;
  border-radius: 3px;
  background-color: #ffffff;
  color: #393939;
  transition: all 0.3s;
  z-index: 5;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.75;
  }

  &.isEditingThisOrder {
    opacity: 0.6 !important;
    background-color: #f2f2f2 !important;
  }

  &.inTimeline {
    max-height: 90px;
    gap: 1px;

    .line {
      margin-top: 0;
    }
  }

  .separator {
    margin: 0 6px;
  }

  h4,
  p {
    margin: 0;
  }

  .line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    margin: 0;

    &.titleLine {
      margin-bottom: -4px;
    }

    &.inTimeline {
      margin-bottom: 0;
    }

    &.isGrey {
      color: #919191;
    }

    .icon {
      position: relative;

      img {
        display: block;
        width: 22px;
        height: 22px;
      }
    }

    .orderIdentifier {
      display: inline-block;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 0.813rem;
      color: #000;
    }

    .orderFootage {
      display: inline-block;
      justify-self: flex-end;
      font-size: 0.75rem;
      color: #adadad;
    }

    .orderCode {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.75rem;
      color: #adadad;

      p {
        color: #adadad;
      }
    }

    .orderName {
      font-size: 0.75rem;
      color: #adadad;
    }

    .orderAddress {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.75rem;
      color: #adadad;
      max-width: 100%;

      p {
        color: #adadad;
      }

      .neighborhoodName {
        color: #3288b9;
      }
    }

    .orderStatus {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      font-size: 0.725rem;
      margin-top: -3px;

      .statusDot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 1px solid #ffffff;
      }

      .statusName {
        margin-top: -1px;
        color: #adadad;
      }
    }

    .orderIcons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      width: 100%;

      img {
        width: 14px;
        height: 14px;
      }
    }

    .orderDate {
      display: inline-block;
      justify-self: flex-end;
      font-size: 0.75rem;
      color: #adadad;
    }
  }
}

.orderDelayed15 {
  border-color: #ff0000;
  border-width: 2px;
}

.orderDelayed30 {
  border-color: #ffc700;
  border-width: 2px;
}

.iconDelayed {
  position: absolute;
  right: 2px;
  top: 0;
}

.isUnavailable {
  border-color: #ff9900;
  border-width: 2px;
}

.isUnavailableBadge {
  width: 22px;
  order: 1;
  margin-top: -6px;
}
