.clientAssignForm {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  .checkbox {
    display: flex;
    align-items: center;
    margin: 1.25em 0 0;
    padding: 0 3.46em;
    font-size: 0.93875em;
    font-weight: 500;

    > span {
      width: auto;
      margin-right: 10px;
    }

    > label {
      margin: 0;

      .inputCheckbox {
        margin-right: 10px;
      }
    }
  }

  .button {
    font-size: 0.9375em;
  }
}

#commercialFranchiseesSelect {
  :global .select {
    &__control {
      &--is-focused {
        box-shadow: none;
      }
    }

    &__placeholder {
      font-size: 12px;
    }

    &__input {
      font-size: 12px;
    }

    &__single-value {
      font-size: 12px;
    }

    &__multi-value {
      border-radius: 15px;
      background-color: #658ec6;
      color: #ffffff;

      &__label {
        padding: 2px 2px 2px 8px;
        font-size: 12px;
        color: #ffffff;
      }

      &__remove {
        border-radius: 0 13px 13px 0;
        padding: 2px 5px 2px 2px;
        transition: background-color 0.3s;
        background-color: #658ec6;
        cursor: pointer;

        &:hover {
          color: #ffffff;
          background-color: darken(#658ec6, 10%);
        }
      }
    }

    &__value-container {
      max-width: calc(100% - 73px);

      [class$='Input'] {
        padding: 0;
        line-height: 0;
      }

      .select__input {
        padding: 4px 0;
        height: 24px;

        input {
          height: 100%;
        }
      }
    }

    &__value-container--is-multi {
      flex-wrap: wrap;
    }

    &__menu {
      .select__menu-list {
        max-height: 148px;
        height: 148px;

        .select__option {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
