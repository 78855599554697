.title {
  font-weight: bold;
  font-size: calc(10px + 0.4vw);
}

.subtitle {
  padding-left: 2vw !important;
  padding-right: 2vw !important;
  font-weight: normal;
  font-size: calc(6px + 0.4vw);
}

.content {
  padding-left: 1vw;
  padding-right: 1vw;
}

.error {
  font-weight: normal;
  font-size: calc(6px + 0.3vw);
}

.success {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.titleSuccess {
  font-weight: bold;
  font-size: calc(14px + 0.4vw);
  display: flex;
  gap: 5px;
}
.Button {
  color: #ffffff;
  margin: 30px 5px;
  width: 180px;
  text-transform: none;
  border: none;
  font-weight: lighter;
  padding: 8px;
  border-radius: 5px;
  background-color: #859905;
}
.divisor {
  margin-top: 10px;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
}
