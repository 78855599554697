.multiline {
  margin-top: 1rem;
}

.multiline > div {
  padding: 0;
}

.bootstrapInput {
  margin: 0;
  border-radius: 4px;
  font-weight: normal;
  border: 1px solid #ccc;
  font-size: calc(6px + 0.4vw);
  padding: 8px 12px;
  width: calc(100% - 24px);
}

.bootstrapInput:focus {
  border-color: #80bdff;
}
