.Search {
  font-family: "Open Sans";
  background-color: #FFFFFF;
  border-radius: 4px;
}

.Input {
  padding-left: 10px;
}

.Paper {
  max-height: 200px;
  overflow-y: auto;
}
