.clientForm {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  .cardHeadWithClientPublicLink {
    @extend .cardHead;

    justify-content: space-between;
  }
}
