.exportGeneralStatistics {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  .button {
    font-size: 0.9375em;
  }

  p {
    max-width: 400px;
    margin: 0 auto 2em;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .statisticsDates {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;

      .startDate,
      .endDate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        input {
          padding: 6px 10px;
        }

        label {
          margin: 0 0 5px 0;
          font-size: 0.75em;
          font-weight: 600;
          text-transform: uppercase;
          color: #3c3c3c;
        }
      }

      .startDate {
        margin-right: 12px;
      }
    }
  }
}

