.Header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.inspectionList {
  margin-bottom: 1vw;
  padding-top: 0;
  padding-left: calc(14px + 1.8vw);
}

.inspection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0px;
  font-size: 12px;

  .inspectionData {
    span {
      font-size: 14px;
      font-weight: 700;
      color: #858585;
    }

    p {
      font-size: 12px;
      color: #858585;
    }
  }

  .actions {
    margin: 10px -5px 0;

    button {
      height: 32px;
      margin: 0 5px;
    }
  }
}

.ListItem span {
  color: #858585;
  font-weight: bold;
  font-size: 15px;
}

.ListItem p {
  color: #858585;
  font-size: 12px;
}

.List button {
  margin: 0px 10px;
}

.SubHeader {
  font-weight: bold;
}

.Icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}
