@import 'src/components/common/Card/shared/style';

.clientDetails {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  >div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    margin: 0 0 1em;
    font-size: 0.93875em;
    font-weight: 700;
    line-height: 1.1;
    color: #393939;

    .icon {
      width: 3.46em;
    }
  }
}

.address {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 0.93875em;
  font-weight: 600;
  color: #919191;

  .text {
    position: relative;
    line-height: 1.5;
  }

  .icon {
    width: 3.46em;
  }
}

.detail {
  margin: 1.25em 0 1.25em 0;
  padding-left: 3.46em;
  font-size: 0.93875em;
  font-weight: 500;
  color: #919191;
}

.disabled {
  background-color: #CED4DA !important;
  opacity: 0.5;
}
