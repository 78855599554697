.Link:any-link {
  color: #858585;
  font-size: 11px;
}

.Header {
  font-weight: bold;
  font-size: calc(6px + 0.3rem);
  padding-top: 0.2rem;
}

.Icon {
  font-size: calc(14px + 0.6rem);
  margin-right: 0.9rem;
  vertical-align: middle;
}

.Header,
.Icon {
  font-family: "Open Sans";
}

* > .Resources:last-child {
  min-height: 10rem;
  flex-wrap: nowrap;
}

.Resources {
  flex-wrap: nowrap;
}

.ListIcon {
  padding: 0;
  margin-right: 0;
  width: 1.2rem;
  margin-top: 1px;
}
