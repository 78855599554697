.Paper {
  margin-left: 1vw;
  margin-right: 1vw;
  background-color: #EAEAEA;
  border: #DEDEDE;
  border-style: solid;
  border-width: 2px;
  min-height: calc(100% - 4px);
}

.center {
  text-align: center;
}

.centerHeight {
  text-align: center;
  height: 100%;
}

.Header {
  font-size: calc(16px + 0.4vw);
  margin-top: 4vh;
}

.SubHeader {
  text-align: left;
  font-size: calc(12px + 0.2vw);
}

.Body {
  margin-top: 40px;
  margin-bottom: 5vh;
}

.Search {
  margin-left: 100px;
}

.textSm{
  font-size: 10px;
}

.optionSelected{
  text-align: left;
  background-color: #F4F3F4;
  padding: 20px;
}

.optionInfo{
  text-align: left;
  display: flex;
  gap: 50px;
}

.optionInfo p{
  margin: 0;
  font-size: 12px;
}

.buttons{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.bold{
  font-weight: bold;
}
