.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // height: 90px;
  background-color: #00BBDD;
  color: #ffffff;
  padding: 20px 15px;

  .container {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    max-width: 980px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .logo {
    max-width: 235px;
    width: 100%;


    img {
      max-width: 100%;
      max-height: 100%;
    }

    @media screen and (max-width: 600px) {
      margin: auto;
      margin-bottom: 10px;
    }
  }

  .headerRigth {
    text-align: right;

    h6 {
      font-weight: bold;
      margin: 0;
      font-size: 16px;
    }

    p {
      color: #000;
      margin: 0;
      font-size: 14px;

    }

    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }



  @media screen and (max-width: 600px) {
    display: block;
  }
}



.Content {
  padding: 50px;
  display: flex;
  background-color: #2B313C;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: #fff;


  .ContentLeft {
    text-align: center;
    width: 50%;

    .Image {
      max-width: 400px;
      width: 100%;
    }

    h3 {
      font-weight: bold;
    }

    div {
      padding: 1.5em 1em;
    }

    @media screen and (max-width: 1060px) {
      display: none;
    }
  }

  .ContentRight {
    width: 50%;

    @media screen and (max-width: 1060px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
}

.Footer {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 20px 10px;
  background-color: #fff;

  .FooterLeft {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    font-size: 12px;

    img {
      max-width: 205px;
      width: 100%;
      margin-bottom: 15px;
    }

    p {
      max-width: 325px;
    }

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }

  .FooterRight {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;

    p {
      font-weight: bold;
      font-size: 18px;
      max-width: 355px;
    }

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    display: block;
  }
}

.Footerbar {
  display: flex;
  background-color: #2B313C;
  padding: 20px 10px;

  div {
    color: #fff;
    width: 33.3333%;
    text-align: center;
    font-size: 12px;

    @media screen and (max-width: 750px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    display: block;
  }
}

.social {
  list-style: none;
  display: flex;
  gap: 30px;
  padding: 0;
}
