.select {
  :global .select {
    &__control {
      &--is-focused {
        box-shadow: none;
      }
    }

    &__placeholder {
      font-size: 12px;
    }

    &__input {
      font-size: 12px;
    }

    &__multi-value {
      border-radius: 15px;
      background-color: #658ec6;
      color: #ffffff;

      &__label {
        padding: 2px 2px 2px 8px;
        font-size: 12px;
        color: #ffffff;
      }

      &__remove {
        border-radius: 0 13px 13px 0;
        padding: 2px 5px 2px 2px;
        transition: background-color .3s;
        background-color: #658ec6;

        &:hover {
          color: #ffffff;
          background-color: darken(#658ec6, 10%);
        }
      }
    }

    &__value-container {
      max-width: calc(100% - 73px);

      [class$="Input"] {
        padding: 0;
        line-height: 0;
      }

      .select__input {
        padding: 4px 0;
        height: 24px;

        input {
          height: 100%;
        }
      }
    }

    &__value-container--is-multi {
      flex-wrap: wrap;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          font-size: 12px;
        }
      }
    }
  }
}