.Paper {
  margin-left: 1vw;
  margin-right: 1vw;
  border-style: solid;
  border-width: 2px;
  min-height: calc(100% - 4px);
}

.Container {
  padding: 50px 0;
}
