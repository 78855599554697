.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  height: 36px;
  margin: 1rem 0;
  padding: 0 1.2em;
  border: 0;
  border-radius: 3px;
  background: #393939;
  font-size: 0.9375em;
  font-weight: 700;
  line-height: 1.4;
  color: #ffffff;
  transition: all 0.3s;

  &:hover {
    opacity: 0.75;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &[disabled] {
    cursor: not-allowed;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .8);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -9px;
      width: 18px;
      height: 18px;
      border: 2px solid #cccccc;
      border-right-color: #333333;
      border-radius: 50%;
      z-index: 2;
      animation: rotate .6s infinite linear;
    }
  }
}