.inspectorFiltersForm {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  .button {
    font-size: 0.9375em;
  }

  h3 {
    margin-bottom: 14px !important;
  }

  h5 {
    margin-bottom: 10px !important;
  }

  ul {
    position: relative;
    height: 200px;
    margin: 0;
    padding: 0;
    border: 2px solid #e3e3e3;
    list-style: none;
    overflow-y: auto;
    border-radius: 4px;

    > .spinner {
      top: 50%;
    }

    button {
      display: block;
      padding: 0 .5rem;
      border: 0;
      background: none;
      width: 100%;
      min-height: 28px;
      text-align: left;

      &:hover {
        background-color: #f4f4f4;
      }

      &:active,
      &:focus {
        outline: 0;
      }

      > p {
        padding: 2px 0;
        margin: 0;
        text-align: left;
        line-height: 1.6;
        font-size: 12px;
        > span {
          word-break: break-word;
          &:first-of-type {
            display: inline-block;
            margin-right: 6px;
          }
          &:last-of-type {
            display: inline-block;
            font-size: .75rem;
            font-weight: 600;
            color: #919191;
          }
        }
      }
    }
  }

  .helper {
    margin: .4rem 0;
    font-size: .75rem;
    text-align: center;
    line-height: 1.4;
    color: #303030;
  }

  .sectionTitle {
    padding: 0 46px;
    margin: 20px 0 6px;

    h4 {
      font-size: 0.75em;
      font-weight: 700;
      text-transform: uppercase;
      color: #393939;
      margin-bottom: 8px;
    }

    p {
      font-size: 0.875em;
      font-weight: 600;
      line-height: 1.15;
      color: #919191;
      list-style: none;
      display: inline-block;
    }
  }

  .addressInputsWrapper {
    padding: 0 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .selectAddress {
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      [class*="addressSelect"] {
        flex: 1;
        width: 100%;
      }
    }

    #selectedNeighborhoodsList {
      margin-top: 0;
      width: 48%;

      ul {
        height: 226px;
      }
    }
  }

  .listWrapper {
    display: flex;
    justify-content: space-between;

    .list {
      flex: 0 0 calc(50% - .5rem);
      width: calc(50% - .5rem);
      margin: 0 0 1rem;
      padding: 0;
    }
  }
}

.addressSelect {
  :global .select {
    &__control {
      &--is-focused {
        box-shadow: none;
      }
    }

    &__placeholder {
      font-size: 12px;
    }

    &__input {
      font-size: 12px;
    }

    &__single-value {
      font-size: 12px;
    }

    &__multi-value {
      border-radius: 15px;
      background-color: #658ec6;
      color: #ffffff;

      &__label {
        padding: 2px 2px 2px 8px;
        font-size: 12px;
        color: #ffffff;
      }

      &__remove {
        border-radius: 0 13px 13px 0;
        padding: 2px 5px 2px 2px;
        transition: background-color 0.3s;
        background-color: #658ec6;

        &:hover {
          color: #ffffff;
          background-color: darken(#658ec6, 10%);
        }
      }
    }

    &__value-container {
      max-width: calc(100% - 73px);

      [class$='Input'] {
        padding: 0;
        line-height: 0;
      }

      .select__input {
        padding: 4px 0;
        height: 24px;

        input {
          height: 100%;
        }
      }
    }

    &__value-container--is-multi {
      flex-wrap: wrap;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          font-size: 12px;
        }
      }
    }
  }
}

.scheduleWrapper {
  display: block;
  min-height: 100%;
  height: calc(100vh - 200px);
  width: 100%;
  margin: 6px auto auto;
  overflow: hidden;
  border: 1px solid #B2B7BD;
  border-radius: 5px;

  &,
  & > * {
    display: flex;
    flex-direction: column;
  }
}

.neighborhoodSubtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1.5em 0 0;

  > button {
    background: none;
    border: none;
    font-size: 0.75rem;
    color: red;
    margin-bottom: 10px;
    transition: opacity .3s;

    &:hover,
    &:disabled {
      opacity: 0.6;
    }
  }
}
