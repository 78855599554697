.currentTime {
  position: absolute;
  top: 25px;
  left: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  width: 0;
  border-right: 1px dashed #4f90c7;
  z-index: 10;
  pointer-events: none;
}
