.field {
  display: flex;
  flex-direction: column;
  margin: 1.25em 0 0;
  padding: 0 3.46em;
  font-size: 0.93875em;
  font-weight: 500;
  color: #919191;

  &:first-child {
    margin-top: 0;
  }

  &.hasCheckbox {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 1.4em 0 2em;

    label {
      margin: 0 0 0 .5em;
    }
  }

  &.hasRadio {
    display: flex;
    flex-direction: row;
    margin: 1.4em 0 2em;

    .radio {
      &:not(:first-child) {
        margin-left: 2em;
      }
    }
  }

  &.isFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .field {
      margin: 0;
      padding: 0;
      font-size: 1em;

      $gap: 10px;
      @for $i from 1 through 12 {
        $multiplier: $i / 12;
        &.is#{$i} {
          width: calc(100% * #{$multiplier} - #{$gap} * (1 - #{$multiplier}));
        }
      }
    }
  }

  &.actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin-top: 2em;

    .button {
      margin: 0 1em 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .label {
    margin: 0;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    color: #393939;
  }

  .value {
    margin: 0.4em 0 0;
    font-size: 0.875em;
    font-weight: 600;
    line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.error {
  padding: .5em;
  font-size: .75em;
  color: #940000;
}

label {
  font-size: 0.75em;
  font-weight: 700;
  text-transform: uppercase;
  color: #393939;
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
select,
textarea {
  height: 3em;
  padding: 0 0.5em;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  background-color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875em;
  font-weight: 600;
  color: #919191;
  transition: border-color 0.3s;

  &:focus,
  &:active {
    outline: 0;
    border-color: #4d8fca;
  }

  &[disabled] {
    background-color: #efefef;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-weight: 400;
    color: #b1b1b1;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-weight: 400;
    color: #b1b1b1;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-weight: 400;
    color: #b1b1b1;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-weight: 400;
    color: #b1b1b1;
  }
}

textarea {
  height: 6em;
  padding: 0.8em 0.5em;
  line-height: 1.3;
  resize: vertical;
}

.radio {
  display: flex;
  align-items: center;

  label {
    margin: 0;
  }

  .radioInput {
    margin-right: 0.5em;
  }
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  height: 36px;
  margin: 0 0.5rem;
  padding: 0 1.2rem;
  border: 0;
  border-radius: 3px;
  background: #c1c1c1;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.4;
  color: #ffffff;
  transition: opacity 0.3s;

  &:hover:not(:disabled) {
    opacity: 0.75;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &[disabled] {
    font-size: 0;
    cursor: not-allowed;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .8);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -9px;
      width: 18px;
      height: 18px;
      border: 2px solid rgba(0, 0, 0, .15);
      border-right-color: rgba(0, 0, 0, .4);
      border-radius: 50%;
      z-index: 2;
      animation: rotate .6s infinite linear;
    }
  }

  &.isGreen {
    border-color: transparent;
    background: #97a500;
    color: #ffffff;
  }

  &.isGray {
    border-color: transparent;
    background: #c1c1c1;
    color: #ffffff;
  }

  &.isRed {
    border-color: transparent;
    background: #c90b0b;
    color: #ffffff;
  }

  .isOutlined {
    height: 32px;
    border: 1px solid #4c8fc9;
    border-radius: 50px;
    background: none;
    color: #4c8fc9;
  }
}

.error {
  margin: .5em 0 0;
  font-size: .87em;
  color: #ed0000;
}

:global .select {
  &__control {
    min-height: 34px;
    border-radius: 3px;
    border: 1px solid #e3e3e3;

    &--is-focused {
      box-shadow: none;
      border-color: #4d8fca;

      &:hover {
        border-color: #4d8fca;
      }
    }
  }

  &__placeholder {
    font-size: 12px;
  }

  &__input {
    font-size: 12px;
  }

  &__single-value {
    font-size: 12px;
  }

  &__multi-value {
    border-radius: 15px;
    background-color: #658ec6;
    color: #ffffff;

    &__label {
      padding: 2px 2px 2px 8px;
      font-size: 12px;
      color: #ffffff;
    }

    &__remove {
      border-radius: 0 13px 13px 0;
      padding: 2px 5px 2px 2px;
      transition: background-color .3s;
      background-color: #658ec6;

      &:hover {
        color: #ffffff;
        background-color: darken(#658ec6, 10%);
      }
    }
  }

  &__value-container {
    max-width: calc(100% - 73px);

    [class$="Input"] {
      padding: 0;
      line-height: 0;
    }

    .select__input {
      padding: 4px 0;
      height: 24px;

      input {
        height: 100%;
      }
    }
  }

  &__value-container--is-multi {
    flex-wrap: wrap;
  }

  &__menu {
    .select__menu-list {
      .select__option {
        font-size: 12px;
        text-align: left;
      }

      .select__menu-notice--no-options {
        font-size: 12px;
      }
    }
  }

  &__indicator {
    padding: 6px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
