body {
  scroll-behavior: smooth;
}

div#dashboards div#loading {
  position: relative;
  height: 320px;
  width: 100%;
  background: #fff;
}

div#dashboards div#loading .spinner {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div#dashboards div#error {
  position: relative;
  height: 320px;
  width: 100%;
  padding-top: 40px;
  background: #fff;
  text-align: center;
}

div#dashboards div#error p {
  margin-top: 8px;
  font-size: 1.25rem;
}

div#dashboards iframe {
  display: block;
  border: none;
}

div#dashboards {
  position: relative;
  background: #eaeaea;
}

div#dashboards section:not(:last-of-type) {
  margin-bottom: 100px;
}

div#dashboards .dashboard-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 12px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #1f4774;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

div#dashboards .dashboard-header a {
  margin-right: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: none !important;
  transition: all 0.3s;
}

div#dashboards .dashboard-header a:last-of-type {
  margin-right: 0;
}

div#dashboards .dashboard-header a:hover {
  color: #eaeaea;
}

div#dashboards .powerbi-wrapper {
  padding: 0;
  height: 680px;
  width: 100%;
}

div#dashboards .arcgis-wrapper {
  padding: 0;
  height: 680px;
  width: 100%;
}

div#dashboards .iframe-wrapper {
  padding: 0;
  height: 680px;
  width: 100%;
}