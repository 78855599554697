.UnknownError {
    z-index: 10000;
    background-color: rgb(243, 1, 1);
    color: white;
    text-align: center;
    position: absolute;
    width: 100%;
    font-size: 12px;
    padding: 5px;
    bottom: 0;
    visibility: hidden;
}

.active {
    visibility: visible;
}
