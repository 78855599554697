.clientForm {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  .button {
    font-size: 0.9375em;
  }

  .profileImage {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .icon {
    cursor: pointer;
    padding: 5px;
  }
}
