.extraActions {
  margin: 0 1vw;
  padding: 48px 0;
  border-radius: 3px;
  background: #ffffff;
  color: #393939;
  text-align: center;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }

  > p {
    padding: 0 10%;
    font-size: 14px;
  }
}

.itemList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 38px -10px 0;
  padding: 0;
  list-style: none;

  li {
    margin: 10px 5px 0;

    button {
      display: flex;
      align-items: center;
      width: 280px;
      padding: 16px;
      border: 2px solid #e3e3e3;
      border-radius: 3px;
      text-align: left;
      transition: border-color .3s;
      cursor: pointer;

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }

      &:not(:disabled) {
        &:hover {
          border-color: #0062cc;
        }

        &:active,
        &:focus {
          outline: 0;
        }
      }
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      margin: 0;
      border: 2px solid #393939;
      border-radius: 50%;
      line-height: 0;
    }

    .itemData {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      h2 {
        font-size: 14px;
        font-weight: 700;
        margin: 0;
      }

      p {
        margin: 5px 0 0 0;
        font-size: 12px;
      }
    }
  }
}
