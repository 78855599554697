.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  animation: fade .3s forwards;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .85);
  z-index: 1;
  cursor: default;

  &:active,
  &:focus {
    outline: 0;
  }
}

.content {
  position: relative;
  min-width: 480px;
  max-width: 80%;
  max-height: 90%;
  overflow-y: scroll;
  padding: 1.5em 1.5em;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: -3px 1px 30px rgba(0, 0, 0, 0.35);
  transform: translate3d(0, -20px, 0);
  opacity: 0;
  z-index: 2;
  animation: slideUp .3s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
