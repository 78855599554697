.home {
  h1 {
    font-size: 1.25em;
    font-weight: 700;
    color: #393939;
  }

  p {
    color: #393939;
    font-size: 0.875em;
  }
}

.spinnerWrapper {
  display: flex;
  padding: 2em 0;
  justify-content: center;
  align-items: center;
}

.serviceList {
  display: flex;
  margin: 0 -1em;
  flex-wrap: wrap;
}

.service {
  margin: 1em;

  a,
  span {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    padding: .5rem;
    border: 2px solid #e3e3e3;
    border-radius: 3px;
    background: none;
    font-size: .875em;
    font-weight: 600;
    text-align: center;
    color: #393939;
    overflow: hidden;

    &:hover {
      border-color: #4d8fca;
      text-decoration: none;
    }

    .image {
      width: 64px;

      >img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .flag {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      background-color: #4D8FCA;
      transform: rotate(45deg);

      &.blocked {
        background-color: #E3E3E3;
      }

      img {
        position: absolute;
        left: 14px;
        bottom: 1px;
        width: 14px;
        transform: rotate(-40deg);
      }
    }
  }

  span {
    background-color: #A7A7A71A;
  }

  &.enabled {

    a {
      border: 2px solid #4d8fca;
    }
  }
}

.ModalHeader {
  display: flex;
  background-color: #2B313C;
  gap: 5px;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.ModalContent {
  display: flex;
  gap: 10px;
  padding: 20px;
}

.ModalImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ModalTitle{
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
