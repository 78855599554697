.modal {
  max-width: 80%;
  max-height: 90%;
  overflow: scroll;
}

.flex {
  display: flex;
}

.preview {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.icon {
  cursor: pointer;
  padding: 5px;
}

.imageWrapper {
  max-height: calc(100vh - 350px);
}
