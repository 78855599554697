.section {
  position: relative;
  flex: 0;
  margin: 0;

  &.is1 {
    flex-basis: 8.3333333%;
  }

  &.is2 {
    flex-basis: 16.6666667%;
  }

  &.is3 {
    flex-basis: 25%;
  }

  &.is4 {
    flex-basis: 33.3333333%;
  }

  &.is5 {
    flex-basis: 41.6666667%;
  }

  &.is6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  &.is7 {
    flex-basis: 58.3333333%;
  }

  &.is8 {
    flex-basis: 66.6666667%;
  }

  &.is9 {
    flex-basis: 75%;
  }

  &.is10 {
    flex-basis: 83.3333333%;
  }

  &.is11 {
    flex-basis: 91.6666667%;
  }

  &.is12 {
    flex-basis: 100%;
  }

  .contentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.withPadding {
      padding: 1.5em 1em;
    }
  }
}
