.multipleCheckboxes {

}

.checkboxList {
  display: flex;
  flex-wrap: wrap;

  .checkbox {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    width: 50%;
    margin: 10px 0;

    label {
      display: flex;
      align-items: center;
      margin: 0;
      text-transform: uppercase;

      .inputCheckbox {
        margin-right: 10px;
      }
    }
  }
}