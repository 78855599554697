.orderCardServiceModal {
  display: flex;
  .modal {
    background-color: white;
    border-radius: 4px;
    margin: auto;
    width: 50%;

    .modalHead {
      background-color: #00bbdd;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #2b313c;
      font-size: 42px;
      font-weight: 900;
      width: 100%;
      padding: 15px;
      text-align: center;

      .containerHead {
        text-transform: uppercase;

        .icon {
          font-size: 56px;
          margin-top: -8px;
        }
        .iconLeft {
          font-size: 56px;
          margin-top: -8px;
          margin-left: -30px;
        }
        .iconRight {
          font-size: 56px;
          margin-top: -8px;
          margin-right: -30px;
        }
      }
    }
    .modalBody {
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;

      .bannerContainer {
        width: 100%;
        margin-top: 25px;
        text-align: center;

        img {
          max-width: 396px;
        }
      }

      .imgContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .imgFooterContainer {
        display: flex;
        margin: auto;
        padding-bottom: 10px;
      }
    }

    .modalFooter {
      background-color: #2b313c;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 37px;
      margin-top: 10px;
    }
  }

  @media (max-width: 1400px) {
    .modal {
      width: 60%;
    }
  }

  @media (max-width: 1100px) {
    .modal {
      width: 70%;
    }
  }

  @media (max-width: 900px) {
    .modal {
      width: 80%;
    }
  }

  @media (max-width: 670px) {
    .modal {
      width: 90%;
      .modalHead {
        font-size: 36px;
      }
      .modalBody {
        font-size: 13px;
      }
    }
  }
}
