.successPage {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  .container {
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
    color: #2B313C;

    .content {
      padding: 20px;
    }

    h2 {
      color: #00BBDD;
      text-align: center;
      font-weight: 900;
      font-size: 26px;
      margin-bottom: 5px;
    }

    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 25px;
    }

    h4 {
      font-weight: 700;
      font-size: 20px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
    }

    .icon {
      margin-right: 5px;
    }

    .image {
      margin: auto;
      display: flex;
    }

    .link {
      cursor: pointer;
      color: #0485BE;
      text-decoration: underline;
      font-weight: 600;
    }

    .uppercase {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
