html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  max-width: 10px;
  max-height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #498dcb;

  &:hover {
    background: #1a3a5f;
  }
}

.feedback-toast .toast-success {
  background-color: #51a351 !important;
}

.fade-in {
  opacity: 0;
  animation: fade-in .5s ease;
  animation-fill-mode: forwards;

  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.1s;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

// [CSSTransition] Fade transition
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}