.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 1vw 10px 1vw;
  margin: auto auto 10px;
}

.clientsFiltersBar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // padding: 5px 1vw 10px 1vw;
  // margin: auto auto 10px;

  button {
    display: inline-block;
    position: relative;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
    background: none;
    color: #000;
    font-size: 0.875rem;
    outline: none;
    font-weight: 600;
    transition: all 0.3s;
    text-align: left;

    &:hover {
      text-shadow: 0 0 .65px #000, 0 0 .65px #000;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      background: #e5825d;
      transition: all 0.3s;
    }

    &:not(:last-of-type) {
      margin-right: 22px;
    }

    &.activeFilter {
      text-shadow: 0 0 .65px #000, 0 0 .65px #000;

      &::before {
        width: 90%;
      }
    }
  }
}
