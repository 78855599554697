.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.75em 1.625em;
  background-color: #fff;
  border: 2px solid #e3e3e3;
  border-radius: 3px;
  line-height: 1;
  color: #393939;

  &.isInspectorStatistics {
    height: 116px;
  }
}

.link {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;

    .card {
      background-color: darken(#fff, 2%);
    }
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &.isSelected {
    &:hover {
      .card {
        &:after {
          border-left-color: darken(#fff, 2%);
        }
      }
    }

    .card {
      border-color: #a6a6a6;
      box-shadow: inset 0 0 0 1px #a6a6a6;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateZ(0);
        width: 0;
        height: 0;
        border: solid transparent;
      }

      &:before {
        right: -22px;
        border-color: #eeeeee;
        border-left-color: #a6a6a6;
        border-width: 10px;
      }

      &:after {
        right: -17px;
        border-left-color: #ffffff;
        border-width: 9px;
      }
    }
  }
}
