.Header {
  font-weight: bold;
  font-size: calc(6px + 0.4vw);
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.Information {
  color: #858585;
  font-size: calc(6px + 0.4vw);
  margin-top: 1vw;
  margin-bottom: 1vw;
  padding-left: calc(14px + 1.8vw);
}

.SubHeader {
  font-weight: bold;
}

.Icon {
  font-size: calc(14px + 0.6vw);
  vertical-align: middle;
  margin-right: 1vw;
}

.ImagesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 6px;

  .Image {
    height: 160px;
  }
}
