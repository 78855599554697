.inspectorOption {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  cursor: pointer;

  > img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}
