.fullscreenSpinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .8);
  z-index: 100;

  .content {
    display: flex;
    align-items: center;
    padding: 2em;
    border-radius: 3px;
    background-color: #ffffff;
    line-height: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, .75);

    .spinner {
      width: 24px;
      height: 24px;
    }

    .text {
      margin-left: .75em;
      font-weight: 700;
    }
  }
}
