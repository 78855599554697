@import 'src/components/common/Card/shared/style';

.packagePurchase {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;
}

.packageList {
  list-style: none;
  margin: 1.875em 0;
  padding: 0 3.3125em;

  .package {
    margin-top: 1.25em;

    &:first-child {
      margin-top: 0;
    }

    input[type='radio'] {
      position: absolute;
      left: -9999px;
      visibility: hidden;

      &:checked {
        + label {
          border-color: #a6a6a6;
          box-shadow: inset 0 0 0 1px #a6a6a6;
        }
      }

      + label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin: 0;
        padding: 0 1.4375em;
        border: 2px solid #e3e3e3;
        border-radius: 3px;
        color: #393939;
        cursor: pointer;

        .packageName {
          font-size: 1em;
          font-weight: 700;
        }

        .packageArea {
          font-size: 0.75em;
        }
      }
    }
  }
}
