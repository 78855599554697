.ClientMenu {
  background-color: #172036;
}

.Tabs {
  margin: auto;
}

.Tab {
  position: relative;
  color: white;
  text-transform: none;
  outline: none !important;
  font-weight: 100;
}

.Tab.active,
.Tab:hover {
  opacity: 1;
}

.indicator {
  background-color: #3a79bf;
  height: 5px;
}

.divergenceCount {
  position: absolute;
  top: 3px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #9e3c5b;
  line-height: 1;
  font-size: 12px;
  color: #fff;
  pointer-events: none;
  z-index: 2;
}
.divider{

  padding: 10px 0;
}
.divider span{
  border-left: 1px solid #fff;
}
.label{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
