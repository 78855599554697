.featureBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  background: #2b313c;
  border-radius: 5px;

  >img {
    display: block;
    position: absolute;

    &:first-of-type {
      width: 156px;
      top: 20px;
      left: 0;
    }

    &:last-of-type {
      width: 200px;
      top: 20px;
      right: 0;
    }

    @media screen and (max-width: 1546px) {
      display: none;
    }
  }

  nav {
    width: 100%;
    height: 100%;
    padding: 4px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;

    @media screen and (max-width: 1546px) {
      gap: 22px;
    }

    .featureBannerMiddleDetail {
      height: auto;
      width: 72px;
    }

    .featureBannerText {
      height: auto;
      width: 500px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 6px 10px;
      background: #fdbc52;
      border-radius: 5px;
      border: none;
      color: #2b313c;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 900;
      transition: all 0.3s;

      &:hover {
        opacity: 0.95;
      }
    }
  }
}

.imageFull {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
}

.fadeIn {
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}
