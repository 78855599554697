.rbc-calendar {
  flex: 1;
  min-height: 580px;
  border-radius: 5px;
}

.rbc-events-container {
  margin-right: 0 !important;
  border-right: 1px solid #dddddd;
}

.rbc-time-header-content {
  border: none !important;
  position: relative;
  margin-right: 16px;
}

.rbc-time-header-content:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 50%;
  background: #dddddd;
}

.rbc-time-header {
  border: none !important;
  position: relative;
}

.rbc-time-header:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 16px;
  width: 1px;
  height: 50%;
  background: #dddddd;
}

.rbc-header:not(:first-of-type) {
  border-left: none
}

.rbc-header > a {
  pointer-events: none !important;
}

.rbc-allday-cell {
  display: none !important;
}

.rbc-time-content {
  border-top: none !important;
  cursor: grab;
  padding-right: 16px;
}

.rbc-time-content::-webkit-scrollbar-thumb {
  background-color: #128FCF;
}

.rbc-time-content:active {
  cursor: grabbing;
}

.rbc-timeslot-group {
  min-height: 28px;
  justify-content: center;
}

.rbc-timeslot-group .rbc-time-slot {
  flex: none;
}

.rbc-timeslot-group .rbc-time-slot .rbc-label {
  font-weight: 600;
  color: #1E1F22;
}
