.addMedia {
  @import 'src/components/common/Form/shared/style';

  padding: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  text-align: left;
}

.info {
  font-size: .875rem;
  text-align: left;
  color: #303030;
}

.error {
  font-size: .5rem;
  text-align: left;
  color: #ff0000;
}