.Container {
  margin-top: 0.5vw;

  div {
    color: #858585;
    font-size: calc(6px + 0.4vw);
    margin-bottom: 0.5vw;
  }

  :last-child {
    margin-bottom: 0;
  }
}
