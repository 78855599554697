.ErrorPage {
    margin-left: 1vw;
    margin-right: 1vw;
    background-color: #EAEAEA;
    border: 2px solid #DEDEDE;
    min-height: calc(100% - 4px);
    padding: 40px;
}

.ErrorPage h2 {
    text-align: center;
}

.Icon {
    text-align: center;
}