.form {
  margin: 0 1vw;
  border-radius: 3px;
  background-color: #ffffff;
  color: #393939;

  h1 {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 55px 10% 55px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid #eeeeee;

    svg {
      font-size: 28px;
      margin-right: 6px;
    }
  }

  form {
    padding: 50px 10%;

    .field {
      margin-top: 10px;

      &.isRightAligned {
        text-align: right;
      }
    }

    legend {
      font-size: 15px;
      font-weight: 700;
    }

    label {
      margin-bottom: 5px;
      font-size: 9px;
      font-weight: 700;
      text-transform: uppercase;
    }

    input {
      padding: 8px 12px;
      font-size: 14px;
      box-sizing: border-box;

      &:focus {
        outline: 0;
        border-color: #80bdff;
        box-shadow: none;
      }
    }

    button[type='submit'] {
      width: 200px;
      height: 46px;
      border: 0;
      border-radius: 3px;
      background-color: #97a500;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
}
