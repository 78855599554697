.fixAddress {
  @import 'src/components/common/Form/shared/style';

  .row {
    display: flex;
    margin-top: 1rem;

    .state {
      flex: 0 0 20%;
      min-width: 110px;
    }

    .city {
      flex: 1;
      margin-left: 22px;
    }

    .neighborhood {
      flex: 1;
    }
  }

  .info {
    padding-top: .5rem;
    font-size: 0.675rem;
    text-align: left;
    color: #c4c4c4;
  }

  .currentAddress {
    font-size: .875rem;
    text-align: left;
    color: #303030;
  }

  .error {
    font-size: .5rem;
    text-align: left;
    color: #ff0000;
  }

  .hasError {
    :global .select__control {
      border-color: red;
    }
  }

  .predictions {
    flex-wrap: wrap;
    list-style: none;
    font-size: .875rem;
    text-align: left;

    .prediction {
      display: contents;
      padding: 0 .4rem;

      &:not(:first-child):before {
        content: '•';
        color: #212529;
        margin: 0 .25rem;
      }

      button {
        display: contents;
        margin-left: .25rem;
        padding: 0;
        border: 0;
        background: none;
        word-wrap: break-word;
        color: #4c8fca;

        &:hover {
          color: lighten(#4c8fca, 10%);
        }
      }
    }
  }
}
