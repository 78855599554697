.realEstateAddressOption {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 10px 12px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: 2px;
  }

  h4 {
    font-size: 0.625rem;
    font-weight: bold;
    margin-bottom: 0;
  }

  p {
    font-size: 0.625rem;
    font-weight: normal;
  }
}
