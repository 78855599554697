.inspectorDetails {
  @import 'src/components/common/Card/shared/style';
  @import 'src/components/common/Form/shared/style';

  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.field {
  margin: 1.75em 0 0;
}

.spinner {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.metricList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -0.75em 0;

  .metric {
    flex: 0 0 calc(50% - 0.75em);
    margin: 0.75em 0;
    padding: 1.12em .75em;
    border: 2px solid #e3e3e3;
    border-radius: 3px;

    h4 {
      margin-bottom: 1.4em;
      padding-bottom: 1.4em;
      border-bottom: 1px solid #eee;
      font-size: 0.8em;
      text-align: center;
      color: #393939;
    }

    .totalNumber {
      font-size: 1.5em;
      font-weight: 700;
      text-align: center;
      color: #393939;
    }
  }
}

.metricsByType {
  margin-top: 1.2em;
  padding: 0 0.875em;
}

.metricItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1em 0;
  font-size: 0.8125em;
  color: #393939;

  &:last-child {
    margin-bottom: 0;
  }

  .type {
    img {
      max-height: 1.75em;
    }
  }

  .number {
    font-weight: 700;
  }
}

.cardHeadWithLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.4em;
  margin-bottom: 1.4em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.3;
  color: #393939;
  border-bottom: 1px solid #eee;

  > div {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 0.5em;

      img {
        width: 26px;
        height: 100%;
      }

      &:not(:first-child) {
        margin-left: 32px;
      }
    }

  }

  > a {
    display: inline-block;
    img {
      width: 100%;
      height: 100%;
      max-width: 18px;
    }
  }
}

.filtersTypesList {
  margin: 14px 0;
  padding: 0 46px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h4 {
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    color: #393939;
    margin-bottom: 8px;
  }

  ul {
    padding: 0;
    li {
      font-size: 0.875em;
      font-weight: 600;
      line-height: 1.4;
      color: #919191;
      list-style: none;
      display: inline-block;
    }
  }

  .firstColumn {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      ul {
        padding: 0;
      }
    }
  }

  .secondColumn {
    flex: 0.5;
  }
}
