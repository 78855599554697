.container {
  padding-left: 1vw;
  padding-right: 1vw;
}

.icon {
  font-size: 100px;
}

.Stepper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row;
}

.StepIcon {
  font-size: 100px;
  padding: 2px;
}

.StepConnector {
  top: 45px;
  left: calc(50% + 50px);
  right: calc(-50% + 50px);
  position: absolute;
}

.StepConnector.lineHorizontal {
  border-top-width: 3px;
}

.wrapper {
  width: 100%;
  padding: 0px 20px;
}

.hr {
  display: block;
  border: 0;
  border-top: 1px solid #E9E9E9;
  padding: 0;
}

.wrapper {
  display: none;
}