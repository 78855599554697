@import 'src/components/common/Card/shared/style';

.clientUsers {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
}
