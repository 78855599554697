.spinner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  max-width: 100%;
  height: 48px;
  max-height: 100%;
  color: #4c8fca;

  svg {
    width: 100%;
    height: 100%;
    animation: rotate 1.4s linear infinite;

    circle {
      stroke: currentColor;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      animation: circular-dash 1.4s ease-in-out infinite;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circular-dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
