.AssignInspector {
  margin-left: 1vw;
  margin-right: 1vw;
  background-color: #eaeaea;
  border: #dedede;
  border-style: solid;
  border-width: 2px;
  min-height: calc(100% - 4px);
  padding: 6vh 0;

  > div {
    max-width: 83%;
    margin: 0 auto;
  }

  label {
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
  }

  hr {
    background-color: #dddddd;
    width: 80%;
    height: 2px;
    margin: 4em auto 2em;
    border: 0;
  }
}

.Title {
  position: relative;

  h2 {
    margin-bottom: 0;
    font-size: calc(16px + 0.4vw);
    text-align: center;
  }

  h3 {
    margin-top: .4em;
    font-size: calc(14px + 0.4vw);
    text-align: center;
    justify-content: center;
    line-height: 1.4;
  }

  .badge {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(calc(50% + 120px));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 25px;
    border-radius: 13px;
    background-color: #4c8fca;
    font-size: .5em;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.InspectorFilter {
  padding: 10px;
}

.Schedule {
  display: flex;
  flex-direction: column;
  padding: 10px;

  input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 6px;
  }
}


.Observations {
  display: flex;
  flex-direction: column;
  padding: 10px;

  textarea {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    resize: none;
    height: 100px;
  }
}

.ErrorMessage {
  text-align: center;
  font-size: 12px;
  padding: 10px;
  color: var(--red);
}

.Actions {
  padding: 10px;
  text-align: center;

  button {
    position: relative;
    height: 45px;
    width: 150px;
    color: #fff;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    margin: 0 10px;
    background: none;

    &.isBusy {
      cursor: not-allowed;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        background-color: rgba(234, 234, 234, .75);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        margin: -12px;
        border: 2px solid #999999;
        border-right-color: #333333;
        border-radius: 50%;
        animation: rotate 1s linear infinite;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .75;
    }

    &.Assign {
      background-color: #859905;
    }

    &.Cancel {
      background-color: #b4b4b4;
    }
  }
}

.content {
  display: flex;
  align-items: flex-start;
  max-width: 90% !important;
  margin-top: 2em !important;
}

.icon {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.text {
  margin-left: 1em;
  line-height: 1.5;
  color: #4f4f4f;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    border-radius: 4px;
    background-color: #1b3a60;
    font-weight: 500;
    color: #ffffff;
    transition: all .3s;

    &:hover {
      text-decoration: none;
      opacity: .75;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
