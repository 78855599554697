.schemaObjectContainer {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.removeNested {
  > div {
    padding-left: 0 !important;
    padding-right: 0 !important;

    > div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
