.title {
  margin-bottom: 0.5rem;
}

.optionList {
  display: flex;
}

.option {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }

  label {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .radio {
    margin-right: .5rem;
  }
}