.radio {
  position: relative;
  width: 16px;
  height: 16px;

  input[type='radio'] {
    position: absolute;
    top: 2px;
    left: 2px;
    opacity: 0;

    &:checked + label {
      &:before {
        transform: scale(1);
      }
    }

    &:disabled + label {
      opacity: .5;
      filter: grayscale(.25);
      cursor: not-allowed;
    }
  }

  label {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 2px solid #4055b3;
    border-radius: 50%;

    &:before {
      content: '';
      width: 6px;
      height: 6px;
      transform: scale(0);
      border-radius: 50%;
      background-color: #4055b3;
      transition: transform 0.3s;
    }
  }
}
