.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  animation: fade .3s forwards;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  cursor: default;

  &:active,
  &:focus {
    outline: 0;
  }
}

.content {
  position: relative;
  max-width: 500px;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: -3px 1px 30px rgba(0, 0, 0, 0.35);
  transform: translate3d(0, -20px, 0);
  opacity: 0;
  z-index: 2;
  animation: slideUp .3s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
