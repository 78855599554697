.item {
  display: flex;
  align-items: center;
}

.input {
  font-size: 9px !important;
  width: 200px;
  margin-right: 5px;
}

.img {
  margin-left: 5px;
}

.coppy {
  cursor: pointer;
}

.label {
  font-size: 0.55em !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #393939 !important;
  display: block;
  margin-bottom: 0;
  margin-right: 5px;
}

.orderCardTooltip {
  background: #5292cd;
  width: 100%;
  max-width: 320px;
}
