/**
* @prettier
**/

.Header {
  font-weight: bold;
  font-size: calc(6px + 0.4rem);
  margin: 0.5rem 0;
}

.Information {
  color: #858585;
  font-size: calc(6px + 0.3rem);
  margin: 0.9rem 0;
  display: flex;
}
.Information > p {
  padding-left: 0.5rem;
}
.Information > .smallIcon {
  margin-left: calc(6px + 1.8rem);
}

.Icon {
  margin-right: 0.9rem;
  font-size: calc(14px + 0.55rem);
  vertical-align: middle;
}

.Header,
.Information,
.Icon {
  font-family: "Open Sans";
}
