.ClientApp {
  min-height: 100vh;
  padding-bottom: 15px;
  background-image: url('../public/assets/img/bg-client.png');
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.Container {
  margin: 15px auto 0;
  padding: 20px 0 0 0;
  background-color: #ffffff;
  border-radius: 4px;
  border: #dedede;
  border-style: solid;
  border-width: 2px;
}

.NotificationContainer {
  margin: 30px auto 0;
}

.Item {
  height: 100%;
  /* overflow: auto; */
}

.modalLogo {
  /* max-width: 860px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modalImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0 -24px;

  img {
    max-width: 960px;
    width: 100%;
  }
}

.modalContent {
  margin-top: 25px;
  line-height: 1.3;
  padding: 0px 15px;

  p {
    color: #000;
    font-size: 25px;

    span {
      font-weight: 600;

      &.bgYellow {
        background-color: #fdbc52;
      }
    }
  }
}

.modalActions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;

  button {
    color: #000;
    background-color: #fdbc52;
    padding: 20px 50px;

    span {
      flex-direction: column;
      font-size: 18px;

      span {
        &:first-child {
          font-weight: bold;
          font-size: 25px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.modalFooter {
  padding: 20px;
  background-color: #2b313c;
  margin: 0px -24px -24px -24px;
}

.clientDashboardPopup {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
