.recipientsField {
  header {
    display: grid;
    grid-template-columns: 1fr 1fr 12px;

    h4 {
      font-weight: bold;
      font-size: calc(6px + 0.3vw);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: grid;
      grid-template-columns: 1fr 1fr 12px;
      column-gap: 6px;

      &:not(:last-of-type) {
        margin-bottom: 12px;
      }

      input {
        border-radius: 4px;
        border: 1px solid #ced4da;
        font-size: calc(6px + 0.3vw);
        padding: 8px 12px;
        width: calc(100% - 24px);
        font-family: 'Open Sans';
        outline: none;

        &:focus {
          border-color: #80bdff;
        }
      }

      > button {
        border: none;
        background: none;
        width: 100%;
        max-width: 20px;
      }
    }
  }

  .addRecipientButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    margin-left: -4px;
    margin-top: 12px;
    cursor: pointer;
    color: #007bff;
    font-weight: normal;
    font-size: 0.625rem;
    border: none;
    background: none;

    .addCircle {
      height: 20px;
    }
  }
}

.select {
  :global .select {
    &__control {
      &--is-focused {
        box-shadow: none;
        border-color: #80bdff;
      }
    }

    &__placeholder {
      font-size: 0.75rem;
    }

    &__input {
      font-size: 0.75rem;
    }

    &__single-value {
      font-size: 0.75rem;
    }

    &__multi-value {
      border-radius: 15px;
      background-color: #80bdff;
      color: #ffffff;
      font-size: 0.75rem !important;

      &__label {
        padding: 2px 2px 2px 8px;
        font-size: 0.625rem;
        font-weight: normal;
        color: #ffffff;
      }

      &__remove {
        border-radius: 0 13px 13px 0;
        padding: 2px 5px 2px 2px;
        transition: background-color 0.3s;
        background-color: #80bdff;
        cursor: pointer;

        &:hover {
          color: #ffffff;
          background-color: darken(#80bdff, 10%);
        }
      }
    }

    &__value-container {
      max-width: calc(100% - 73px);
      font-weight: normal;

      [class$='Input'] {
        padding: 0;
        line-height: 0;
      }

      .select__input {
        padding: 4px 0;
        height: 24px;

        input {
          height: 100%;
        }
      }
    }

    &__value-container--is-multi {
      flex-wrap: wrap;
    }

    &__menu {
      .select__menu-list {
        .select__option {
          font-size: 0.75rem;
          cursor: pointer;
        }
      }
    }
  }
}
