@import 'src/components/common/Card/shared/style';

.clientSummary {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list {
  margin-bottom: 0;

  .empty {
    margin-top: 2em;
  }

  .item {
    &:last-child {
      border-bottom: 1px solid #eeeeee;
    }
  }
}

.pagination {
  display: flex;
  margin: 1.75em auto 0;

  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 20px;
    padding: 0;
    margin: 0 2.5px;
    border: 1px solid transparent;
    border-radius: 50px;
    background: none;
    font-family: 'Open Sans', sans-serif;
    font-size: .875em;
    cursor: pointer;

    &:hover {
      opacity: .75;
    }

    &:active,
    &:focus {
      outline: 0;
    }

    &[disabled] {
      opacity: .25;
      cursor: not-allowed;
    }

    &.isActive {
      border-color: #393939;
    }

    &.isFirst,
    &.isLast {
      width: 20px;
      height: 20px;
      border-color: #393939;
    }
  }

  .omission {
    display: flex;
    align-items: flex-end;
    height: 16px;
    cursor: not-allowed;
  }
}

.arrowLeft {
  position: relative;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #393939;
  border-top: 0;
  border-right: 0;
  transform: rotate(45deg);
}

.arrowRight {
  position: relative;
  right: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #393939;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(45deg);
}

.filter {
  position: relative;
  margin-bottom: 1.75em;
  padding: 0 0 1.75em;
  border-bottom: 1px solid #eeeeee;
  z-index: 3;

  > div {
    margin-right: 0;
  }
}