@import 'src/scss/shared/variables';

.header {
  display: flex;
  flex: 0 0 $header-height;
  min-height: $header-min-height;

  .left {
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 $menu-width;
    min-width: $menu-width;
    max-width: $menu-width;
    background-color: $dark-blue;
    line-height: 1;
    color: $text-light;

    .logo {
      width: 120px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 1em;
    background-color: $dark-grey;
    color: $text-dark;

    .searchWrapper {
      flex: 0 0 50%;
      padding-right: 1em;

      >div {
        margin-right: 0;
      }
    }

    .user {
      display: flex;
      align-items: center;
      height: 100%;

      .userName {
        font-size: 0.9em;
        font-weight: 700;
        line-height: 1.2;
      }

      .menuButton {
        margin: 0 0 0 0.8em;
        width: 32px;
        height: 32px;
        padding: 0;
        border: 0;
        border-radius: 50%;
        background-color: #153253;
        line-height: 0;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
          opacity: 0.8;
        }

        &:active,
        &:focus {
          outline: 0;
        }

        svg {
          font-size: 28px;
        }
      }
    }
  }
}

.userMenu {
  position: absolute;
  right: 1em;
  transform: translateY(calc(100% + 0.65em));
  width: 200px;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: 10px;
    transform: rotate(45deg);
    width: 0;
    height: 0;
    border: solid 0.5625em transparent;
    border-color: #ffffff transparent transparent #ffffff;
    border-width: 6px;
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.08)
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    li {
      margin: 0.2em 0;

      button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 1em;
        width: 100%;
        height: 32px;
        border: 0;
        background: none;

        &:hover {
          background: darken(#ffffff, 2%);
        }

        &:active,
        &:focus {
          outline: 0;
          background: darken(#ffffff, 5%);
        }
      }
    }
  }
}

:global {
  .menu-enter {
    opacity: 0;
    transform: translateY(calc(100% + 0.65em)) translate3d(0, -6px, 0);
  }

  .menu-enter-active {
    opacity: 1;
    transform: translateY(calc(100% + 0.65em)) translate3d(0, 0, 0);
    transition: opacity .3s, transform .3s ease;
  }

  .menu-exit {
    opacity: 1;
    transform: translateY(calc(100% + 0.65em)) translate3d(0, 0, 0);
  }

  .menu-exit-active {
    opacity: 0;
    transform: translateY(calc(100% + 0.65em)) translate3d(0, -6px, 0);
    transition: opacity .3s, transform .3s ease;
  }
}



.left.sidebarCollapsed {
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 70px;
  min-width: 70px;
  max-width: 70px;
  background-color: $dark-blue;
  line-height: 1;
  color: $text-light;

  .logo {

    svg {
      width: 70px;
      height: 60px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
