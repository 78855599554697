@import 'src/components/common/Card/shared/style';

.clientFullDetails {
  display: flex;
  flex-direction: column;
  padding: 1.5em 1em;

  @import 'src/components/common/Form/shared/style';

  > div {
    margin: 0.3em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-bottom: -1em;
  }
}

.field {
  margin: 1.75em 0 0;
}