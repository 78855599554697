.cardList {
  height: 100%;
  overflow: auto;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 1.5em 1em;
    overflow: hidden;

    li {
      margin: .3em 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 auto 1rem;

  svg {
    width: 28px;
    height: 28px;
  }
}
